
.ldi-blockDowloadList {
	&__list {
		@include media('>xSmall') {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-right: -$base;
			margin-left: -$base;
		}

		.ldi-block--alignLeft & {
			@include media('>xSmall') {
				justify-content: flex-start;
			}
		}

		.ldi-block--alignCenter & {
			@include media('>xSmall') {
				justify-content: center;
			}
		}

		.ldi-block--alignRight & {
			@include media('>xSmall') {
				justify-content: flex-end;
			}
		}

		.ldi-block__title + & {
			padding-top: $base;
		}

		.ldi-block__text + & {
			padding-top: $base3;
		}
	}


	&__listElem {
		padding-top: $base;

		@include media('>xSmall') {
			width: 50%;
			box-sizing: border-box;
			padding: $base4 $base 0 $base;
		}
		@include media('>small') {
			width: #{(1/3) * 100%};
		}

		&:nth-child(1) {
			padding-top: 0;
		}

		&:nth-child(1),
		&:nth-child(2) {
			@include media('>xSmall') {
				padding-top: 0;
			}
		}

		&:nth-child(3) {
			@include media('>small') {
				padding-top: 0;
			}
		}
	}
}
