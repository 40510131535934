.ldi-projectTab {
	position: relative;
	border-bottom: 1px solid currentColor;


	&__label {
		.ldi-projectTab.ldi-js-current & {
			font-weight: bold;
		}
	}


	&__progress {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		height: $projectTabProgressHeight;
		background-color: currentColor;
		transform-origin: 0 0;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		transform: scaleX(0%);
	}
}
