
$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;

$mainMaxWidth: $base * 140;
$overlayMaxWidth: $base * 112;
$overlayLargeMaxWidth: $base * 140;
$textMaxWidth: $base * 81;

$blockMobileVerticalPadding: $base2;
$blockVerticalPadding: $base7;
$blockContentMaxWidth: $base * 102;
$minSearchBlockHeight: 80vh;

$mainPadding: 5vw;

$containerPadding: $base3;
$containerPaddingSmall: $base2;

$iconBtnHeight: $base * 5.5;
$iconBtnMobileHeight: $base * 4.9;

$btnWidth: $base * 30;
$btnWidthSmall: $btnWidth - $base2;

$suPagePreviewRatio: 16 / 9;
$partnerRatio: 16 / 9;
$issuuRatio: 16 / 9;
$videoPlayerRatio: 16 / 9;
$blockBgImageRatio: 16 / 8;
$teamMemberRatio: 1 / 1;


$headerHeight: $base8;
$headerMobileHeight: $base6;
$headerLineSize: 1px;
$headerLineHoverSize: 3px;
$subMenuSmallerHeight: $base5;

$logoDotSize: $base * 0.9;

$searchThumbWidth: 20%;
$previewThumbWidth: 20%;

$overPageActionCount: 4;

$minTextareaHeight: $base * 15;
$maxTextareaHeight: $base * 80;

$projectTabProgressHeight: 2px;

$notificationMaxWidth: $blockContentMaxWidth;

$slideRatioSmall: 1 / 1;
$slideRatioLarge: 3 / 2;
$slideRatioLargeFullWidth: 20 / 9;
