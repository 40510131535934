
.ldi-blockVideo {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;


	&__playerBtn {
		@include ease(background-color, $videoHoverDuration);
		background-color: transparentize($invertedBgColor, $videoImgOpacity);
		color: $invertedColor;
		display: block;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 100%;

		body.ldi-js-highContrast & {
			background-color: transparentize($invertedBgColor, 0.1);
		}

		&:active,
		html:not(.mobile) &:hover,
		html:not(.mobile) &:focus {
			background-color: transparentize($invertedBgColor, $videoImgOpacityHover);
		}

		body.ldi-js-highContrast &:active,
		html:not(.mobile) body.ldi-js-highContrast &:hover,
		html:not(.mobile) body.ldi-js-highContrast &:focus {
			background-color: transparentize($invertedBgColor, 0.05);
		}


		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $videoPlayerRatio * 100%);
		}

		.ldi-js-visiblePlayer & {
			display: none;
		}

		.ldi-blockVideo:not(.ldi-block--bgImg) & {
			background-color: $invertedBgColor;
		}
	}


	&__playerBtnIcon {
		display: block;
		left: $base;
		line-height: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: $base3;

		@include media('>small') {
			left: $base3;
			width: $base5;
		}

		span[data-type="svg"] {
			width: 100%;
			height: auto;
		}

		.ldi-overlay__page > .ldi-blocks > .ldi-block--video & {
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			@include media('>small') {
				left: 50%;
			}
		}
	}


	&__player {
		display: none;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 100%;


		.ldi-js-visiblePlayer & {
			display: block;
		}

		figure {
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				display: block;
				padding-bottom: (1 / $videoPlayerRatio * 100%);
			}


			> iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}


	& &__title {
		@include ldi-fontSize(videoCaption);
		color: $invertedColor;
		font-weight: normal;
		left: $base5;
		max-width: auto;
		position: absolute;
		right: $base;
		top: 50%;
		transform: translateY(-50%);

		@include media('>small') {
			@include ldi-fontSize(h2);
			left: $base10;
			right: $base2;
		}
	}

	&__privacy {
		@include ldi-fontSize(videoCaption);
		color: $baseColor;

		@include media('>small') {
			color: $invertedColor;
			position: absolute;
			transform: translateY(-100%);
			width: 50%;
			right: 0;
			padding-left: $base1;
			padding-bottom: $base1;
		}
	}


	&__privacyLink {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}

		&:focus {
			outline: dashed $baseColor 1px !important;

			@include media('>small') {
				outline: dashed $invertedColor 1px !important;
			}
		}
	}
}
