.ldi-dialog {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 300px;
	height: auto;
	z-index: 2;


	// &__container {
	// 	width: 100%;
	// 	height: 100%;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	flex-direction: column;
	// 	flex-grow: 1;
	// 	flex-shrink: 0;
	// 	margin: 0 $base2;

	// 	max-width: $dialogMaxWidth;

	// 	@include media('<dialogSmallWidth') {
	// 		max-width: $dialogMaxWidthSmall;
	// 	}

	// 	@include media('height<dialogSmallHeight') {
	// 		max-width: $dialogMaxWidthLandscape;
	// 	}


	// 	&::before, &::after {
	// 		content: '';
	// 		width: 0;
	// 		height: auto;
	// 		flex-grow: 1;
	// 		flex-shrink: 1;
	// 		border-left: 1px solid $baseColor;
	// 	}

	// 	&::before {
	// 		margin-top: $base8;
	// 	}


	// 	&::after {
	// 		margin-bottom: $base8;
	// 	}
	// }


	// &__content {
	// 	width: 100%;
	// 	height: 100%;
	// 	flex-shrink: 0;
	// 	flex-grow: 1;
	// 	display: flex;
	// 	flex-direction: column;
	// 	color: $dialogColor;
	// 	background-color: $dialogBgColor;
	// 	margin: $base2 0;
	// 	padding: $base2;

	// 	max-height: $dialogMaxHeight;

	// 	@include media('height<dialogSmallHeight') {
	// 		max-height: $dialogMaxHeightLandscape;
	// 	}

	// }


	// &__title {
	// 	flex-shrink: 0;
	// 	flex-grow: 0;
	// 	padding-bottom: $base;
	// 	margin-bottom: $base;
	// 	border-bottom: 1px solid $invertedBorderColor;
	// 	@include ldi-fontSize(dialogTitle);
	// }


	// &__text {
	// 	position: relative;
	// 	flex-shrink: 1;
	// 	flex-grow: 1;
	// 	@include ldi-fontSize(dialogText);
	// }


	// &__buttons {
	// 	flex-shrink: 0;
	// 	flex-grow: 0;
	// 	text-align: center;

	// 	@include ldi-fontSize(dialogText);
	// 	@include clearfix();
	// }


	// &__button {
	// 	width: 100%;
	// 	max-width: $maxButtonWidth;


	// 	&--splitted {
	// 		width: calc(50% - #{$base / 2});
	// 		float: left;
	// 	}


	// 	&--splitted + &--splitted {
	// 		margin-left: $base;
	// 	}
	// }

	// &__button + &__button:not(&__button--splitted) {
	// 	margin-top: $base;
	// }
}
