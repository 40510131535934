.ldi-download {
	position: relative;
	display: block;
	// width: $newsModuleWidth;

	text-align: center;
	word-break: break-all;

	&__previewHolder {
		text-align: center;
		position: relative;
		background: $alternativeBgColor;
		display: block;
		height: $base * 16;
		padding: 0 0 $base2 0;

		.ldi-block--colorSchemeAlternative & {
			background: $baseBgColor;
		}
	}

	&__previewImg {
		max-width: 80%;
		width: auto;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__previewIcon,
	& &__previewIcon {
		width: $base * 6;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}


	&__previewType {
		background: $invertedBgColor;
		color: $invertedColor;

		text-transform: uppercase;
		font-size: 0.75em;

		padding: 0 5px 0 5px;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, calc(#{$base * 3.6} - 50%));
	}

	// &__caption {
	// 	display: block;
	// 	margin: $base2 0 0 0;
	//
	// 	font-size: 1.5rem;
	// 	line-height: 1;
	// 	word-break: normal;
	// }

	&__info {
		@include ease(color background-color, $hoverDuration);
		display: block;
		width: 100%;
		padding: $base;

		color: $baseColor;
		background-color: $baseBgColor;

		.ldi-block--colorSchemeAlternative & {
			color: $invertedColor;
			background: $invertedBgColor;
		}

		.ldi-download:focus &,
		html:not(.mobile) .ldi-download:hover &,
		html.mobile .ldi-download:active & {
			color: $baseBgColor;
			background-color: $baseColor;
		}

		.ldi-block--colorSchemeAlternative .ldi-download:focus &,
		html:not(.mobile) .ldi-block--colorSchemeAlternative .ldi-download:hover &,
		html.mobile .ldi-block--colorSchemeAlternative .ldi-download:active & {
			color: $invertedBgColor;
			background-color: $invertedColor;
		}
	}
}
