
.ldi-tag {

	@mixin baseScheme($bgTransparent) {
		@if ($bgTransparent) {
			background-color: transparent;
		} @else {
			background-color: $baseBgColor;
		}
		color: $linkColor;
		border: 1px solid $linkColor;
	}

	@mixin invertedScheme($bgTransparent) {
		@if ($bgTransparent) {
			background-color: transparent;
		} @else {
			background-color: $linkColor;
		}
		color: $baseBgColor;
		border: 1px solid $baseBgColor;
	}


	@include button($base);
	@include ease(color background-color border-color, $hoverDuration);
	@include baseScheme(true);
	padding: $base $base2;
	font-weight: normal;
	position: relative;

	&:not(&--fixed) {
		&:focus,
		html:not(.mobile) &:hover,
		&:active {
			@include invertedScheme(false);
		}
	}

	&--fixed {
		cursor: default;
	}

	&--selected {
		@include invertedScheme(false);

		&:not(.ldi-tag--fixed) {
			&:focus,
			html:not(.mobile) &:hover,
			&:active {
				@include baseScheme(true);
			}
		}
	}


	&--colorSchemeInverted {
		@include invertedScheme(true);

		&:not(.ldi-tag--fixed) {
			&:focus,
			html:not(.mobile) &:hover,
			&:active {
				@include baseScheme(false);
			}
		}

		&.ldi-tag--selected {
			@include baseScheme(false);

			&:not(.ldi-tag--fixed) {
				&:focus,
				html:not(.mobile) &:hover,
				&:active {
					@include invertedScheme(true);
				}
			}

		}
	}


	&__amount {
		opacity: 0.6;

		&::before {
			content: '(';
		}

		&::after {
			content: ')';
		}
	}


	&__icon {
		height: 1em;

		.ldi-tagSelection__list--tags & {
			display: none;
		}

		svg {
			fill: currentColor;
		}
	}

	&__icon + &__label {
		.ldi-tagSelection__list--tags & {
			margin-left: 0;
		}
	}
}
