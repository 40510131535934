.ldi-overlay {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	min-height: 100vh;
	z-index: 2;
	padding: $base2 0;
	display: none;
	@include fadeout($easeDuration2);



	@include media('>small') {
		padding: $base8 0;
	}


	&.ldi-js-beforeActive {
		display: block;
	}


	&.ldi-js-duringActive {
		@include fadein;
	}


	&__actions {
		@include ldi-container;
		max-width: $overlayMaxWidth;
		z-index: 2;
		height: 0;
		top: 0;
		text-align: right;
		position: sticky;

		@include media('>small') {
			position: relative;
		}

		.ldi-overlay.ldi-js-largeWidth & {
			max-width: $overlayLargeMaxWidth;
		}
	}


	&__content {
		position: relative;

		.ldi-overlay:not(.ldi-js-beforeActive) & {
			height: 0;
			overflow: hidden;
		}

		@include ldi-container;
		max-width: $overlayMaxWidth;

		.ldi-overlay.ldi-js-largeWidth & {
			max-width: $overlayLargeMaxWidth;
		}


		.ldi-overlay--account & {
		}
	}

	&__page {
		@include ldi-container();
		max-width: none;
		margin-left: 0;
		margin-right: 0;
		width: auto;
		background-color: $baseBgColor;
		position: relative;
		// margin-right: $base5;
		padding-top: $containerPaddingSmall;

		@include media('>small') {
			padding-top: $containerPadding;
			margin-right: $iconBtnHeight + $base;
		}

		// @for $i from 0 through 9 {
			$minI: $overPageActionCount; //if($i > 1, $i, 1);
			// &--btnCount#{$i} {
				min-height: $minI * $base5 - $base;

				@include media('>small') {
					min-height: $minI * $base8 - $base;
				}
		// 	}
		// }

		&--btnCount5 {
			min-height: $base4 * 5 + $base * 4;

			@include media('>small') {
				min-height: $base7 * 5 + $base * 4;
			}
		}
	}

	// &__title {
	// 	@include ldi-fontSize(h2);
	// 	padding-left: $mainPadding;
	// 	padding-right: $mainPadding;
	// }

	// &__intro {
	// 	padding: 0;

	// 	> p {
 	// 		padding: 0 $mainPadding;
	// 	}
	// }

	&__issuu {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: (1 / $videoPlayerRatio * 100%);

		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0; top: 0;
		}
	}
}
