@mixin ldi-menuHoverEffect($onCurrentLink: false) {
	&::after {
		@include ease(opacity, $hoverDuration);
		content: '';
		position: absolute;
		height: $headerLineHoverSize;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translateY(1px);
		background-color: currentColor;
		opacity: 0;
		backface-visibility: hidden;
	}

	@if $onCurrentLink {
		&.ldi-js-current::after {
			opacity: 1;
		}
	}

	&:focus,
	html:not(.mobile) &:hover,
	html.mobile &:active {
		&::after {
			opacity: 1;
		}
	}

}
