
.ldi-subpagesPreview {
	margin-bottom: 0;


	.ldi-columns + & {
		margin-top: $base5;
	}

	@include media('>small') {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	&--fullWidth {
		display: block;
	}

	&__page {
		position: relative;
		margin-bottom: $base3;

		&:last-child {
			margin-bottom: 0;
		}

		&[data-ldi-animate-on-intersection] {
			@include ldi-animateOnIntersection();
		}

		.ldi-subpagesPreview:not(.ldi-subpagesPreview--fullWidth) & {
			@include media('>small') {
				width: calc(100% / 3 - #{$base3 * 2 / 3} - 1px);
				margin-bottom: $base5;
				margin-right: $base3;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				// last three elements
				&:nth-last-child(-n + 3) {
					margin-bottom: 0;
				}
			}
		}


		.ldi-subpagesPreview--fullWidth & {
			width: 100%;
			margin-right: 0;
		}

	}

	&__titleImage {
		display: block;
		position: relative;
		width: 100%;
		margin-bottom: $base;
		border-bottom: none;

		html.no-object-fit & {
			overflow: hidden;
		}

		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $suPagePreviewRatio * 100%);
		}

		figure {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;

			html.no-object-fit & {
				@include center(both);
				width: auto;
				height: auto;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}

	&__title {
		font-weight: normal;
		padding-top: $base;
	}


	&__text {

		.ldi-subpagesPreview__title + & {
			margin-top: $base;
		}
	}


	&__readMore {
		font-weight: bold;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		margin-top: $base;
		display: inline-block;
		@include ease(border-bottom, $hoverDuration);


		.ldi-subpagesPreview__titleImage:focus ~ .ldi-subpagesPreview__text  &,
		.ldi-subpagesPreview__titleImage:hover ~ .ldi-subpagesPreview__text  &,
		&:focus,
		&:hover {
			border-bottom: 1px solid transparent;
		}

		p > & {
			margin-top: 0;
		}
	}
}
