@mixin fadeDisplay($way) {

	animation-duration: 1s;

	@if $way == "in" {
		animation-name: fadeInFromNone;
	} @else if $way == "out" {
		animation-name: fadeOutToNone;
	}
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeOutToNone {
	0% {
		display: block;
		opacity: 1;
	}

	99% {
		display: block;
		opacity: 0;
	}

	100% {
		display: none;
		opacity: 0;
	}
}

@mixin fadein() {
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}

@mixin fadeout($duration:$easeDuration, $ease:$easeFunction) {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s $ease $duration, opacity $duration $ease;
}
