
.ldi-teamMembers {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.ldi-block__title + & {
		margin-top: $base2;

		@include media('>small') {
			margin-top: $base3;
			margin-left: -$base3;
		}
	}

	&__member {
		position: relative;
		width: 100%;
		margin-bottom: $base2;

		@include media('>small') {
			margin-bottom: $base3;
			margin-left: $base3;
		}

		&--defaultWidth {
			@include media('>small') {
				width: calc(33.333% - #{$base3});
			}
		}

		&--halfWidth {
			@include media('>small') {
				width: calc(50% - #{$base3});
			}
		}
	}

	&__img {
		background-color: $invertedBgColor;
		position: relative;
		width: 100%;

		&--empty {}

		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $teamMemberRatio * 100%);

			html.no-object-fit & {
				display: none;
			}

		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;

			html.no-object-fit & {
                //@include center(both);
				//@include ease(all, 0.3s);
				position: relative;
				width: 100%;
				height: auto;
                // width: auto;
                // height: auto;
                // min-width: 100%;
                // min-height: 100%;
            }
		}
	}


	&__info {
		@include ldi-fontSize(small);
		position: relative;
		margin-top: $base;
	}



}
