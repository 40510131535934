.ldi-page {
	@include ease(opacity visibility, $easeDuration2);

	&.ldi-js-leaving {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		opacity: 0;
		visibility: hidden;
	}


	&.ldi-js-entering {
		position: relative;
		z-index: 1;
	}


	&__form {
		* + & {
			margin-top: $base4;
		}
	}
}
