.ldi-slide {
	@include ease(opacity visibility, $slideshowFadeDuration);
	flex-shrink: 0;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	z-index: 1;

	// overlap all on the same position
	&:not(:first-child) {
		margin-left: -100%;
	}

	&.ldi-js-current {
		opacity: 1;
		visibility: inherit;
		z-index: 2;
	}


	&__caption {
		@include ease(opacity visibility, $easeDuration);
		bottom: 100%;
		left: 0;
		opacity: 0;
		padding: $base2 $base $base $base;
		position: absolute;
		text-align: right;
		visibility: hidden;
		width: 100%;

		@include media('>small') {
			bottom: auto;
			display: inline-block;
			left: auto;
			opacity: 1;
			padding: 0;
			position: static;
			transition: none;
			text-align: left;
			visibility: inherit;
			width: auto;
		}

		&.ldi-js-show {
			opacity: 1;
			visibility: inherit;
		}

		.ldi-block--colorSchemeDefault & {
			color: $baseColor;
			background-color: $baseBgColor;
		}

		.ldi-block--colorSchemeAlternative & {
			color: $alternativeColor;
			background-color: $alternativeBgColor;
		}

		.ldi-block--colorSchemeInverted & {
			color: $invertedColor;
			background-color: $invertedBgColor;
		}
	}


	&__captionContainer {
		padding: $base $base 0 $base;
		display: flex;
		position: relative;

		@include media('>small') {
			display: block;
			padding: $base 0 0 0;
		}

		.ldi-block--widthMax & {
			@include media('>small') {
				padding: $base $containerPadding 0 $containerPadding;
			}
		}
	}

	&__captionInfo {
		flex-grow: 1;

		@include media('>small') {
			display: inline-block;
			min-width: 4em;
		}
	}


	&__captionToggler {
		@include ease(color background-color, $easeDuration);
		background-color: transparent;
		border: 1px solid $baseColor;
		border-radius: 50%;
		flex-grow: 0;
		font-size: 2.2rem;
		font-weight: bold;
		line-height: 0;
		height: $base3;
		width: $base3;
		position: relative;
		text-align: center;

		@include media('>small') {
			display: none;
		}

		.ldi-block--colorSchemeDefault & {
			color: $baseColor;

			&.ldi-js-toggled {
				background-color: $invertedBgColor;
				color: $invertedColor;
			}
		}

		.ldi-block--colorSchemeAlternative & {
			color: $alternativeColor;

			&.ldi-js-toggled {
				background-color: $invertedBgColor;
				color: $invertedColor;
			}
		}

		.ldi-block--colorSchemeInverted & {
			border-color: $invertedColor;
			color: $invertedColor;

			&.ldi-js-toggled {
				background-color: $baseBgColor;
				color: $baseColor;
			}
		}



		&::before {
			content: 'i';
			text-align: center;
			display: inline-block;
			width: 100%;
		}
	}


	&__captionTogglerLabel {
		@include visuallyHidden();
	}


	&__figure {
		padding-bottom: (100% / $slideRatioSmall);
		position: relative;
		width: 100%;

		@include media('>small') {
			padding-bottom: (100% / $slideRatioLarge);
		}

		.ldi-block--widthMax & {
			@include media('>small') {
				padding-bottom: (100% / $slideRatioLargeFullWidth);
			}
		}
	}


	&__img {
		@include objectFit(cover);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.ldi-block--widthDefault & {
			@include media('>small') {
				@include objectFit(contain);
			}
		}
	}
}
