
@mixin ldi-tilesWallColumnsDefault() {

	&--defaultWidth {
		@include media('>small') {
			width: calc(33.333% - #{$base3 * 2 / 3});
		}
	}

	&--halfWidth {
		@include media('>small') {
			width: calc(50% - #{$base3 / 2});
		}
	}

	&--fullWidth {
		@include media('>small') {
			width: 100%;
		}
	}
}
