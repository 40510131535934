.ldi-block {

	@mixin removeAdjacentSpace($scheme) {
		&--noBgImg.ldi-block--colorScheme#{$scheme}:not(.ldi-blockTilesWall__tile) + &--noBgImg.ldi-block--colorScheme#{$scheme},
		&--noBgImg.ldi-block--colorScheme#{$scheme}:not(.ldi-blockTilesWall__tile) + &--video.ldi-block--colorScheme#{$scheme},
		&--noBgImg.ldi-block--colorScheme#{$scheme}:not(.ldi-blockTilesWall__tile) + &--publication.ldi-block--colorScheme#{$scheme},
		&--video.ldi-block--colorScheme#{$scheme} + &--noBgImg.ldi-block--colorScheme#{$scheme}:not(.ldi-blockTilesWall__tile),
		&--publication.ldi-block--colorScheme#{$scheme} + &--noBgImg.ldi-block--colorScheme#{$scheme}:not(.ldi-blockTilesWall__tile) {
			padding-top: 0;
		}
	}


	@mixin removeExtraTileSpace($scheme) {
		&--tilesWall.ldi-block--colorScheme#{$scheme} {
			.ldi-block--noBgImg.ldi-block--colorScheme#{$scheme},
			.ldi-block--publication.ldi-block--colorScheme#{$scheme},
			.ldi-block--videoWithoutContent.ldi-block--colorScheme#{$scheme} {
				padding: 0;
			}
		}
	}


	@include ldi-container;
	max-width: none;
	display: block;
	position: relative;
	overflow: hidden;
	color: $baseColor;

	padding-top: $blockMobileVerticalPadding;
	padding-bottom: $blockMobileVerticalPadding;

	@include media('>small') {
		padding-top: $blockVerticalPadding;
		padding-bottom: $blockVerticalPadding;
	}

	// remove adjacent margins when they have the same colors
	@include removeAdjacentSpace('Default');
	// @include removeAdjacentSpace('Inverted');
	@include removeAdjacentSpace('Alternative');
	@include removeAdjacentSpace('Dmp2019');
	@include removeAdjacentSpace('Dmp2020');
	@include removeAdjacentSpace('Dmp2021');
	@include removeAdjacentSpace('AgylePurple');
	@include removeAdjacentSpace('AgylePink');
	@include removeAdjacentSpace('AgyleBlue');
	@include removeAdjacentSpace('AgyleTurquoise');
	@include removeAdjacentSpace('AgyleOrange');
	@include removeAdjacentSpace('DigitaleGray');
	@include removeAdjacentSpace('DigitaleBlue');
	@include removeAdjacentSpace('DigitaleGreen');


	&--slideshow {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;

		&.ldi-block--widthMax {
			padding-top: 0;

			@include media('>small') {
				padding-left: 0;
				padding-right: 0;
				padding-top: 0;
			}
		}

		&.ldi-block--widthDefault {
			@include media('>small') {
				padding-left: $containerPadding;
				padding-right: $containerPadding;
				padding-top: $blockVerticalPadding;
			}
		}
	}


	&--fullImg {
		padding-left: 0;
		padding-right: 0;
	}

	&--bgVideo,
	&--fullSize {
		padding: 0;

		@include media('>small') {
			padding: 0;
		}
	}

	&--noVerticalMargins {
		padding-bottom: 0;
		padding-top: 0;

		@include ldi-blocksFirstBlock {
			padding-top: $headerMobileHeight;

			@include media('>small') {
				padding-top: $headerHeight;
			}
		}
	}


	&--noBgImg {
		overflow: visible;
	}


	&__titleImage + .ldi-blocks__subBlocks > &--noBgImg.ldi-block--colorSchemeDefault:first-child,
	&__titleImage + .ldi-blocks__subBlocks > &--video.ldi-block--colorSchemeDefault:first-child,
	&__titleImage + .ldi-blocks__subBlocks > &--publication.ldi-block--colorSchemeDefault:first-child {
		padding-top: 0;
	}


	.ldi-page--projectEdit & {
		overflow: visible;
	}


	@include removeExtraTileSpace('Default');
	@include removeExtraTileSpace('Inverted');
	@include removeExtraTileSpace('Alternative');
	@include removeExtraTileSpace('Dmp2019');
	@include removeExtraTileSpace('Dmp2020');
	@include removeExtraTileSpace('Dmp2021');
	@include removeExtraTileSpace('AgylePurple');
	@include removeExtraTileSpace('AgylePink');
	@include removeExtraTileSpace('AgyleBlue');
	@include removeExtraTileSpace('AgyleTurquoise');
	@include removeExtraTileSpace('AgyleOrange');
	@include removeExtraTileSpace('DigitaleGray');
	@include removeExtraTileSpace('DigitaleBlue');
	@include removeExtraTileSpace('DigitaleGreen');


	.ldi-block--tilesWall > .ldi-block__content > &--videoWithoutContent {
		padding: 0;

		@include media('>small') {
			padding: 0;
		}
	}


	// &--noBgImg.ldi-block--colorSchemeDefault,
	// &--noBgImg.ldi-block--colorSchemeInverted,
	// &--noBgImg.ldi-block--colorSchemeAlternative {
	// 	&:not(.ldi-blockTilesWall__tile) + &:not(.ldi-blockTilesWall__tile) {
	// 		padding-top: 0;
	// 	}
	// }

	&--tile {
		margin-bottom: $base2;

		@include media('>small') {
			margin-bottom: $base3;
			margin-left: 0;
			margin-right: 0;
		}
	}


	&--colorSchemeDefault {
		color: $baseColor;
		background-color: $baseBgColor;
	}

	&--colorSchemeAlternative {
		color: $alternativeColor;
		background-color: $alternativeBgColor;
	}

	&--colorSchemeInverted {
		color: $invertedColor;
		background-color: $invertedBgColor;

		&:not(.ldi-block--tile):not(.ldi-block--bgImg):not(.ldi-block--slideshow) {
			margin-top: 1px;
			margin-bottom: 1px;
		}

		&:not(.ldi-block--tile):first-child {
			margin-top: 0;
		}
	}

	&--colorSchemeDmp2019 {
		color: $dmp2019Color;
		background-color: $dmp2019BgColor;
	}

	&--colorSchemeDmp2020 {
		color: $dmp2020Color;
		background-color: $dmp2020BgColor;
	}

	&--colorSchemeDmp2021 {
		color: $dmp2021Color;
		background-color: $dmp2021BgColor;
	}

	&--colorSchemeAgylePurple {
		color: $agylePurpleColor;
		background-color: $agylePurpleBgColor;
	}

	&--colorSchemeAgylePink {
		color: $agylePinkColor;
		background-color: $agylePinkBgColor;
	}

	&--colorSchemeAgyleBlue {
		color: $agyleBlueColor;
		background-color: $agyleBlueBgColor;
	}

	&--colorSchemeAgyleTurquoise {
		color: $agyleTurquoiseColor;
		background-color: $agyleTurquoiseBgColor;
	}

	&--colorSchemeAgyleOrange {
		color: $agyleOrangeColor;
		background-color: $agyleOrangeBgColor;
	}

	&--colorSchemeDigitaleGray {
		color: $digitaleGrayColor;
		background-color: $digitaleGrayBgColor;
	}

	&--colorSchemeDigitaleBlue {
		color: $digitaleBlueColor;
		background-color: $digitaleBlueBgColor;
	}

	&--colorSchemeDigitaleGreen {
		color: $digitaleGreenColor;
		background-color: $digitaleGreenBgColor;
	}

	&--alignLeft {
		text-align: left;
	}

	&--alignCenter {
		text-align: center;
	}

	&--alignRight {
		text-align: right;
	}


	.ldi-overlay & {
		padding-left: 0;
		padding-right: 0;

		@include media('>small') {
			padding-left: 0;
			padding-right: 0;
		}
	}


	&:not(.ldi-block--bgVideo):not(.ldi-block--noVerticalMargins) {
		@include ldi-blocksFirstBlock() {
			.ldi-body & {
				padding-top: #{$headerMobileHeight + $blockMobileVerticalPadding};

				@include media('>small') {
					padding-top: #{$headerHeight + $blockVerticalPadding};
				}
			}

			.ldi-overlay & {
				padding-top: 0;
				// padding-top: $containerPaddingSmall;

				@include media('>small') {
					padding-top: 0;
					// padding-top: $containerPadding;
				}
			}

			.ldi-body.ldi-js-subMenu & {
				padding-top: #{$headerMobileHeight * 1.5 + $blockMobileVerticalPadding};

				@include media('>small') {
					padding-top: #{$headerHeight * 1.5 + $blockVerticalPadding};
				}
			}
		}
	}


	.ldi-blocks--columns & {
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;

		@include media('>small') {
			padding-top: $containerPadding;
			padding-bottom: $containerPadding;
		}
	}


	.ldi-block--tilesWall & {
		padding-top: $base;
		padding-bottom: $base;

		@include media('>small') {
			padding-top: $base2;
			padding-bottom: $base2;
		}
	}

	.ldi-blocks--pageActions &:nth-last-child(2) {
		padding-bottom: 0;

		@include media('>small') {
			padding-bottom: 0;
		}
	}

	&--teaser,
	&--search {
		padding-top: $blockMobileVerticalPadding + $base3;
		padding-bottom: $blockMobileVerticalPadding + $base3;

		@include media('>small') {
			padding-top: $blockVerticalPadding + $base6;
			padding-bottom: $blockVerticalPadding + $base6;
		}

		.ldi-block--tilesWall & {
			padding-top: $blockMobileVerticalPadding;
			padding-bottom: $blockMobileVerticalPadding;

			@include media('>small') {
				padding-top: $blockVerticalPadding;
				padding-bottom: $blockVerticalPadding;
			}
		}
	}


	&--pageActions {
		padding-top: 0;

		@include media('>small') {
			padding-top: 0;
		}
	}

	&--tagSelection {
		padding-top: $blockMobileVerticalPadding + $base1;
		padding-bottom: $base1;

		@include media('>small') {
			padding-top: $blockVerticalPadding + $base1;
			padding-bottom: $base1;
		}
	}


	&--bgImg {
		min-height: 100vw / $blockBgImageRatio;

		.ldi-block--tilesWall &,
		&.ldi-block--linkedTile,
		&.ldi-block--video,
		&.ldi-block--publication {
			min-height: 0;
		}
	}


	&--linkedTile {
		&,
		.ldi-block--tilesWall & {

			padding-top: $blockMobileVerticalPadding + $base8;
			padding-bottom: $blockMobileVerticalPadding + $base8;

			@include media('>small') {
				padding-top: $blockVerticalPadding + $base10;
				padding-bottom: $blockVerticalPadding + $base10;
			}
		}
	}


	&__mainLink {
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}


	&__pageTitle {
		@include ldi-fontSize(h1);
		margin-bottom: $base3;

		@include media('>small') {
			margin-bottom: $base5;
		}
	}


	&__titleImage {
		text-align: center;
		// margin-bottom: $base7;

		img {
			width: 100%;
		}
	}


	&__subTitle {
		@include ldi-fontSize(h2);
		display: block;

		.ldi-block--tilesWall & {
			@include ldi-fontSize(base);
		}

		.ldi-block__titleImage + &,
		.ldi-block__title + & {
			margin-bottom: $base2;
		}
	}



	//NOTE: generalize likList!
	&__links {
		margin-left: auto;
		margin-right: auto;

		&:not(:first-child) {
			margin-top: $base3;

			@include media('>xSmall') {
				margin-top: $base7;
			}
		}
	}

	&__linkItem {
		max-width: 100%;
		margin-bottom: $base;

		@include media('>xSmall') {
			display: inline-block;
			max-width: calc(100% - #{$base});
			margin-left: $base * 0.5;
			margin-right: $base * 0.5;
		}
	}


	&__logos {
		// max-width: $textMaxWidth;

		&:not(:first-child) {
			margin-top: $base5;

			@include media('>xSmall') {
				margin-top: $base10;
			}
		}
	}


	&__icon {
		text-align: center;
		padding-top: $base2;

		@include media('>xSmall') {
			padding-top: $base5;
		}

		span[data-type="svg"] {
			width: $base3;

			@include media('>xSmall') {
				width: $base5;
			}
		}
	}


	&__pagination {
		max-width: $blockContentMaxWidth;
		margin: $base3 auto $base2 auto;

		@include media('>small') {
			margin-bottom: $base7;
		}
	}

	&__partnerImage {
		margin-bottom: $base;

		@include media('>small') {
			margin-bottom: $base3;
		}

		img {
			width: 100%;
		}
	}
}

@import "./block-elements/content";
@import "./block-elements/bg-img";
@import "./block-elements/title";
@import "./block-elements/text";
@import "./block-elements/full-img";
