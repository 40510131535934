
.ldi-blockBgVideo {
	&__embed {
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}


	&__player {
		display: block;
		padding-bottom: (1 / $videoPlayerRatio * 100%);
		position: relative;


		&::after {
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}

}
