.ldi-slideshow {
	position: relative;


	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		touch-action: pan-y;
	}


	&__itemsContainer {
		.ldi-block--widthDefault & {
			@include media('>small') {
				padding: 0 $base7;
				// padding: $blockVerticalPadding $base7 $base2 $base7;
			}
		}
	}
}
