.ldi-block {
	&__bgImg {
		position: absolute;
		top: -1px;//NOTE: yes px :-)
		right: -1px;
		bottom: -1px;
		left: -1px;


		body.ldi-js-highContrast .ldi-block:not(.ldi-block--video) & {
			display: none;
			// opacity: 0.1;
		}


		html.no-object-fit & {
			overflow: hidden;
		}

		.ldi-block--publication & {
			position: static;
			max-width: $blockContentMaxWidth;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		.ldi-block--video & {
			position: relative;
			width: 100%;
			left: auto;
			right: auto;
			top: 0;
			bottom: auto;
			order: 0;

			background-color: $invertedBgColor;
			max-width: $blockContentMaxWidth;
			margin: 0 auto;

			&::before {
				content: ' ';
				display: block;
				padding-bottom: (1 / $videoPlayerRatio * 100%);
			}

			.ldi-overlay & {
				left: 0;
				right: 0;
				max-width: none;
			}

			.ldi-blocks--columns & {
				margin-top: $containerPaddingSmall;
				margin-bottom: $containerPaddingSmall;

				@include media('>small') {
					margin-top: $containerPadding;
					margin-bottom: $containerPadding;
				}
			}
		}


		img {
			opacity: $blockBgImgOpacity;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			position: absolute;
			left: 0;
			top: 0;

			.ldi-block--bgImgContain & {
				object-fit: contain;
			}

			html.no-object-fit & {
				@include center(both);
				width: auto;
				height: auto;
				min-width: 100%;
				min-height: 100%;
			}

			.ldi-block--bgImgNoFilter & {
				opacity: 1;
			}

			.ldi-block--publication & {
				opacity: 1;
				position: relative;
			}

			.ldi-block--video & {
				opacity: 1;
				position: absolute;
				top: 0;
				left: 0;
			}

			.ldi-block--video.ldi-js-visiblePlayer & {
				display: none;
			}


			.ldi-block--teaser &,
			.ldi-block--search & {
				position: absolute;
				left: 50%;
				top: 0;
				transform-origin: 50% 0;
				transform: translateX(-50%);
				backface-visibility: hidden;
				transform-style: preserve-3d;
			}

			.ldi-block--teaser.ldi-js-insideViewport &,
			.ldi-block--search.ldi-js-insideViewport & {
				will-change: transform;
			}
		}

		figcaption {
			@include ldi-container;
			@include ldi-fontSize(smallerCaption);
			margin-top: 0;
			position: relative;
			transform: translateY(-100%);
			padding-bottom: $containerPaddingSmall;
			color: inherit;
			opacity: $blockBgImgCaptionOpacity;

			max-width: $blockContentMaxWidth + $containerPaddingSmall + $containerPaddingSmall;

			@include media('>small') {
				max-width: $blockContentMaxWidth + $containerPadding + $containerPadding;
				padding-bottom: $containerPadding;
			}

			.ldi-block--video &,
			.ldi-block--teaser &,
			.ldi-block--search & {
				position: absolute;
				bottom: 0;
				transform: none;
				max-width: none;
			}
		}
	}
}
