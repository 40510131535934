.ldi-notification {
	@include ldi-fontSize(base);
	padding: $base4 $base2;
	color: $notificationColor;
	background-color: $notificationBgColor;


	&__container {
		position: relative;
		margin: auto;
		max-width: $notificationMaxWidth;
		text-align: left;
	}

	&.ldi-js-active {
		opacity: 1;
		transform: translateY(100%);
	}

}
