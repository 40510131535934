
.ldi-logoList {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: stretch;
	align-content: stretch;
	font-size: 0;
	background-color: $baseBgColor;

	html.ms & {
		justify-content: space-around;
	}



	&__listElem {
		display: inline-block;
		flex-shrink: 0;
		flex-grow: 0;
		// align-items: center;
		text-align: center;
	}


	&__link {
		@include ease(background-color, $hoverDuration);
		display: block;
		position: relative;
		height: 100%;
		padding: $base2;
		background-color: $baseColor;

		.ldi-block--colorSchemeDefault & {
			background-color: $baseBgColor;
			// no changes
		}

		.ldi-block--colorSchemeAlternative & {
			background-color: $alternativeColor;
		}

		.ldi-block--colorSchemeInverted & {
			background-color: $invertedColor;
		}

		.ldi-block--colorSchemeDmp2019 & {
			background-color: $dmp2019BgColor;
		}

		.ldi-block--colorSchemeDmp2020 & {
			background-color: $dmp2020BgColor;
		}

		.ldi-block--colorSchemeDmp2021 & {
			background-color: $dmp2021BgColor;
		}

		.ldi-block--colorSchemeAgylePurple & {
			background-color: $agylePurpleBgColor;
		}

		.ldi-block--colorSchemeAgylePink & {
			background-color: $agylePinkBgColor;
		}

		.ldi-block--colorSchemeAgyleBlue & {
			background-color: $agyleBlueBgColor;
		}

		.ldi-block--colorSchemeAgyleTurquoise & {
			background-color: $agyleTurquoiseBgColor;
		}

		.ldi-block--colorSchemeAgyleOrange & {
			background-color: $agyleOrangeBgColor;
		}

		.ldi-block--colorSchemeDigitaleGray & {
			background-color: $digitaleGrayBgColor;
		}

		.ldi-block--colorSchemeDigitaleBlue & {
			background-color: $digitaleBlueBgColor;
		}

		.ldi-block--colorSchemeDigitaleGreen & {
			background-color: $digitaleGreenBgColor;
		}

		&:focus,
		&:not(.ldi-logoList__link--noUrl):hover {
			// background-color: $baseBgColor;
			// .ldi-block--colorSchemeDefault & {}
			// .ldi-block--colorSchemeAlternative & {
			// 	background-color: $alternativeBgColor;
			// }
			// .ldi-block--colorSchemeInverted & {
			// 	background-color: $invertedBgColor;
			// }
			background-color: $logoListHoverBaseBgColor;

			.ldi-block--colorSchemeDefault &,
			.ldi-block--colorSchemeAlternative & {
				// empty
			}

			.ldi-block--colorSchemeInverted & {
				background-color: $logoListHoverInvertedBgColor;
			}
		}
	}


	&__logo {
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		img {
			max-height: $base4;
		}
	}
}
