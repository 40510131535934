.ldi-projectSection {
	@include ease(visibility opacity, $easeDuration2);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	visibility: hidden;
	opacity: 0;

	&.ldi-js-current {
		position:relative;
		visibility: inherit;
		opacity: 1;
	}

	&__description {
		margin-bottom: $base5;
	}

}
