.ldi-project {
	position: relative;

	&__info {
		display: block;
		position: sticky;
		left: 0;
		top: 0;
		background-color: $baseBgColor;
		padding: $base2 0;
		z-index: 200;
	}

	&__tabs {
		position: relative;
		max-width: $textMaxWidth;
	}


	// comment

	&__progress {
		position: absolute;
		right: 0;
		top: $base2;
	}


	&__sections {
		position: relative;
		overflow: hidden;
	}

	&__subtitle {
		.ldi-block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

			@include media('>small') {
				margin-top: $blockVerticalPadding;
			}
		}
	}

	&__text {
		* + & {
			margin-top: $base5;
		}

		.ldi-block__titleImage + & {
			margin-top: $blockMobileVerticalPadding;

			@include media('>small') {
				margin-top: $blockVerticalPadding;
			}
		}


		a:hover {
			border-bottom: 1px solid currentColor;
		}
	}
}
