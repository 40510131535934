.ldi-pankowForm {
	@include ldi-sidePadding();
	position: relative;
	display: block;
	width: 100%;

	&__iframe {
		border: 0;
		width: 100%;
	}
}
