
.ldi-eventsPreview {
	* + & {
		margin-top: $base3;

		@include media('>small') {
			margin-top: $base5;
		}
	}


	&__event {
		margin-bottom: $base3;
		padding-bottom: $base3;
		border-bottom: solid currentColor 1px;

		&:last-child {
			margin-bottom: 0;
		}

		@include media('>small') {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}

	&__column {
		&--meta {
			padding-bottom: $base2;

			@include media('>small') {
				padding-right: $base * 10;
				padding-bottom: 0;
			}
		}


		&--main {
			flex-grow: 1;
			text-align: left;
			@include media('>small') {
				padding-right: $base * 10;
			}
		}


		&--sub {
			padding-top: $base2;

			@include media('>small') {
				padding-top: 0;
				padding-right: 0;
				align-self: center;
			}
			@include media('>medium') {
				padding-right: $base * 10;
			}
		}
	}


	&__date {
		@include media('>small') {
			display: block;
		}
	}

	&__city {
		.ldi-eventsPreview__date + &::before {
			content: '–';
			padding-left: $base;
			padding-right: $base;
			display: inline-block;

			@include media('>small') {
				display: none;
			}
		}
		@include media('>small') {
			display: block;
		}
	}


	&__link {
		white-space: nowrap;
		font-weight: bold;

		@include media('>small') {
			padding-right: $base * 0;
			align-self: center;
		}
		// @include media('>medium') {
		// 	padding-right: $base * 10;
		// }
	}


	&__title {
		@include ldi-fontSize(h2);
		font-weight: normal;
	}
}
