
.ldi-cookieNotification {
	@include ldi-container();
	@include ease(opacity visibility, $easeDuration2);
	max-width: none;
	position: sticky;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 500;
	padding-top: $base2;
	padding-bottom: $base2;
	color: $invertedColor;
	background-color: $invertedBgColor;

	&.ldi-js-hidden {
		opacity: 0;
		visibility: hidden;
	}


	&__content {
		max-width: $blockContentMaxWidth;
		margin: auto;
		@include media('>small') {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}
	}


	&__text {
		margin-bottom: $base2;

		@include media('>small') {
			margin-bottom: 0;
			margin-right: $base2;
			flex-shrink: 1;
			flex-grow: 1;
		}
	}

	&__button {
		@include media('>small') {
			flex-shrink: 0;
			flex-grow: 0;
		}
	}
}
