$baseColor: #1d1d1d;
$baseBgColor: #ffffff;
$baseLineColor: $baseColor;
$overlayFullBgColor: #000000;
$linkColor: $baseColor;
$highlightColor: #c8e6fa;

$invertedColor: $baseBgColor;
$invertedBgColor: $baseColor;
$invertedLineColor: $baseBgColor;

$alternativeColor: $baseColor;
$alternativeBgColor: #f5f5f5;
$alternativeLineColor: $baseColor;

$videoBgColor: $baseColor;

$searchCoverColor: transparentize($overlayFullBgColor, 0.15);

$headerBgColor: transparentize($baseBgColor, 0.1);
$headerBgColorHighContrast: $baseBgColor;

// $headerLineColor: #b3b3b3;
$headerLineColor: transparentize($baseColor, 0.7);

$footerColor: $baseColor;
$footerBgColor: #e8e8e8;
$subFooterColor: $invertedColor;
$subFooterBgColor: $invertedBgColor;

$logoColor1: #1d1d1b;
$logoColor2: #c91a1f;
$logoColor3: #f2bd31;

$logoListHoverBaseBgColor: #333333;
$logoListHoverInvertedBgColor: #e5e5e5;

$partnerBgColor: #e5e5e5;

$errorColor: #e05327;

$dmp2019Color: $invertedColor;
$dmp2019BgColor: #ffb600;
$dmp2020Color: $invertedColor;
$dmp2020BgColor: #bcd900;

$dmp2021Color: $invertedColor;
$dmp2021BgColor: #72c7d8;

$agylePurpleColor: $baseBgColor;
$agylePurpleBgColor: #4b0099;
$agylePinkColor: $baseBgColor;
$agylePinkBgColor: #d423a8;
$agyleBlueColor: $baseBgColor;
$agyleBlueBgColor: #1e91ff;
$agyleTurquoiseColor: $baseBgColor;
$agyleTurquoiseBgColor: #00b9a0;
$agyleOrangeColor: $baseBgColor;
$agyleOrangeBgColor: #ef8200;

$digitaleGrayColor: $baseBgColor;
$digitaleGrayBgColor: #464646;
$digitaleBlueColor: #000000;
$digitaleBlueBgColor: #14a0dc;
$digitaleGreenColor: #000000;
$digitaleGreenBgColor: #32c864;

$blockBgImgOpacity: 0.6;
$blockBgImgCaptionOpacity: 0.7;

$notificationColor: $baseBgColor;
$notificationBgColor: transparentize($baseColor, 0.1);

$checkIconColor: #00ac8a;

$newsletterButtonColor: $invertedColor;
$newsletterButtonBgColor: #db4131;
