
.ldi-video {
	position: relative;
	width: 100%;
	background-color: $invertedBgColor;

	// keeping correct aspect ratio
	&::before,
	&__playerBtn::before,
	&__player::before {
		content: '';
		display: block;
		padding-bottom: (1 / $videoPlayerRatio * 100%);
	}


	&__playerBtn {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 2;
		color: $invertedColor;

		.ldi-video.ldi-js-visiblePlayer & {
			display: none;
		}
	}


	& &__playerBtnIcon {
		display: block;
		left: $base;
		line-height: 0;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: $base3;

		@include media('>small') {
			left: $base3;
			width: $base5;
		}

		span[data-type="svg"] {
			width: 100%;
			height: auto;
		}
	}

	&__image {
		background-color: $invertedBgColor;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		html.no-object-fit & {
			overflow: hidden;
		}


		img {
			@include ease(opacity, $videoHoverDuration);
			@include objectFit(cover);
			display: block;
			width: 100%;
			height: 100%;
			opacity: $videoImgOpacity;

			body.ldi-js-highContrast & {
				opacity: 0.1;
			}

			.ldi-video__playerBtn:active &,
			html:not(.mobile) .ldi-video__playerBtn:hover &,
			html:not(.mobile) .ldi-video__playerBtn:focus & {
				opacity: $videoImgOpacityHover;
			}

			body.ldi-js-highContrast .ldi-video__playerBtn:active &,
			html:not(.mobile) body.ldi-js-highContrast .ldi-video__playerBtn:hover &,
			html:not(.mobile) body.ldi-js-highContrast .ldi-video__playerBtn:focus & {
				opacity: 0.05;
			}
		}
	}


	&__player {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 1;


		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}


	& &__title {
		@include ldi-fontSize(videoCaption);
		color: $invertedColor;
		font-weight: normal;
		left: $base5;
		margin: 0;
		max-width: auto;
		position: absolute;
		right: $base;
		top: 50%;
		transform: translateY(-50%);

		@include media('>small') {
			@include ldi-fontSize(h2);
			left: $base10;
			max-width: auto;
			right: $base2;
		}
	}
}
