
.ldi-button {
	@include button($base);
	@include ease(color background-color border-color, $hoverDuration);
	padding: $base $base2;
	font-weight: normal;
	position: relative;
	background-color: $linkColor;
	color: $baseBgColor;
	border: 1px solid transparent;
	width: 100%;

	@include media('>xSmall') {
		width: auto;
		min-width: $btnWidthSmall;
	}

	@include media('>small') {
		width: auto;
		min-width: $btnWidth;
	}

	&--upload {
		overflow: hidden;
	}

	&--seamless,
	&--accountSecondary {
		background-color: transparent;
		border-color: transparent;
		border-width: 0 0 0 0;
		width: auto;
		min-width: auto;
		color: currentColor;
	}

	&--seamless {
		padding-left: 0;
		padding-right: 0;
	}

	&--accountSecondary {
		padding: 0;
	}


	&:focus,
	&:hover,
	&:active {
		color: $linkColor;
		background-color: $baseBgColor;
		border-color: $linkColor;
	}

	&--colorSchemeAlternative {
		&:hover,
		&:active {
			background-color: $alternativeBgColor;
		}
	}

	&--colorSchemeInverted {
		background: transparent;
		border: 1px solid $alternativeBgColor;

		&:focus,
		&:hover,
		&:active {
			border-color: $alternativeBgColor;
		}
	}

	&--colorSchemeDmp2019 {
		// empty, looking like the default
	}



	.ldi-blockTilesWall & {
		@include media('screen') {
			width: 100%;
			min-width: 0;
		}
	}


	.ldi-pagination & {
		min-width: 0;
	}


	&__icon {
		height: 1em;

		&--flipH {
			transform: scaleX(-1);
		}

		svg {
			fill: currentColor;
		}
	}


	&__label {
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;

		.ldi-button--seamless:focus &,
		html:not(.mobile) .ldi-button--seamless:hover &,
		html.mobile .ldi-button--seamless:active &,
		.ldi-button--accountSecondary:focus &,
		html:not(.mobile) .ldi-button--accountSecondary:hover &,
		html.mobile .ldi-button--accountSecondary:active & {
			border-bottom-color: currentColor;
		}

		.ldi-pagination__tile & {
			border: 0;
		}

		.ldi-form & {
			text-align: left;
		}
	}
}
