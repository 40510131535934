.ldi-footer {
	position: relative;

	[data-type="svg"] {
		display: inline-block;
		height: $base * 2.3;
		vertical-align: middle;
		width: auto;

		// @include media('>small') {
		// 	height: $base * 2;
		// 	width: auto;
		// }
	}
}
