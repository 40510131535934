.ldi-projectView {

	&__section {
		& + & {
			margin-top: $base7;
		}
	}


	&__sectionTitle {
		margin-bottom: $base3;
	}

	&__field {
		border-bottom: 1px solid $baseLineColor;
		padding-top: $base2;
		padding-bottom: $base2;

		&:first-child {
			border-top: 1px solid $baseLineColor;
		}

		@include media('>=small') {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&--image {

			@include media('>=small') {
				flex-direction: row-reverse;
			}
		}
	}


	&__fieldLabel {
		font-weight: bold;
		padding-bottom: $base / 2;

		@include media('>=small') {
			padding-bottom: 0;
			padding-right: $base2;
			width: 40%;
		}
	}


	&__fieldValue {
		@include media('>=small') {
			width: 60%;
		}
	}

	&__imagefield {

		& + & {
			margin-top: $base2;
		}

	}


	&__imagefieldLabel {
		font-weight: bold;
		padding-bottom: $base / 2;
	}

	&__imageWrapper {
		padding-bottom: $base2;

		@include media('>=small') {
			padding-bottom: 0;
			width: 60%;
		}
	}


	&__imageFields {
		@include media('>=small') {
			width: 40%;
			padding-right: $base2;
		}
	}

	&__image {
		width: 100%;
		height: auto;
	}

}
