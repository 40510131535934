.ldi-newsletterButton {
	$duration: $easeDuration * 1.5;

	@include ldi-fontSize(newsletterButton);
	font-weight: 400;
	@include ease(width, $duration);
	background-color: $newsletterButtonBgColor;
	color: $newsletterButtonColor;
	position: fixed;
	right: $base2;
	bottom: $base2;
	z-index: 30;
	display: inline-flex;
	height: $base * 4.8;
	line-height: $base * 4.8;
	align-items: center;
	padding: 0;
	overflow: hidden;
	width: $base * 16;
	justify-content: center;
	border-radius: $base * 2.4;

	@include media('>small') {
		right: $base3;
		bottom: $base4;
	}

	&:not(:hover) {
		width: $base * 4.8;
	}

	&__icon {
		@include ease(opacity visibility, $duration);
		width: $base * 2.6;
		line-height: 0;
		position: absolute;
		left: $base * 2.4;
		top: $base * 2.4;
		transform: translate(-50%, -50%);

		.ldi-newsletterButton:hover & {
			opacity: 0;
			visibility: hidden;
		}
	}

	&__label {
		@include ease(opacity visibility, $duration);
		opacity: 0;
		visibility: hidden;

		.ldi-newsletterButton:hover & {
			opacity: 1;
			visibility: inherit;
		}
	}
}
