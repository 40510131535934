.ldi-header {
	@include ldi-container();
	@include ease(transform border-bottom, $easeDuration);
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 3;
	background-color: $headerBgColor;
	max-width: none;
	border-bottom: solid $headerLineSize $headerLineColor;
	transform: translateY(0%);
	min-height: $headerMobileHeight;

	body.ldi-js-highContrast & {
		background-color: $headerBgColorHighContrast;
	}

	body.ldi-js-editProject & {
		position: absolute;
	}

	@include media('>small') {
		min-height: $headerHeight;

		&:not(.ldi-js-menuOverflow) {
			height: auto;
			min-height: $headerHeight;
			padding-left: 0;
			padding-right: 0;

			// display: flex;
			// flex-direction: row;
			// flex-wrap: nowrap;
			// justify-content: flex-end;
			// align-items: flex-start;
		}
	}


	&.ldi-js-scrolledDown {
		transform: translateY(-100%) translateY(-2px);

		body.ldi-js-editProject & {
			transform: none;
		}
	}

	&.ldi-js-open {
		position: relative;
		transform: none;
		border-bottom: 0;
		padding-bottom: $base4;
	}

	&.ldi-js-subMenu {
		padding-bottom: 0;
		border-bottom: 0;

		&:not(.ldi-js-menuOverflow) {
			@include media('>small') {
				border-bottom: solid $headerLineSize $headerLineColor;
				transform: translateY(-50%) translateY(-2px);

				&.ldi-js-scrolledDown {
					transform: translateY(-200%) translateY(-2px);
				}
			}
		}
	}

	&.ldi-js-subMenuAndMainMenu {
		&:not(.ldi-js-menuOverflow) {
			@include media('>small') {
				backface-visibility: hidden;
				transform: translateY(0);
			}
		}
	}

	&__wrapper {
		@include media('>small') {
			.ldi-header:not(.ldi-js-menuOverflow) & {
				position: relative;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
			}
		}


		&--main {
			body.ldi-js-account .ldi-header & {
				display: none;
			}
		}


		&--account {
			body:not(.ldi-js-account) .ldi-header & {
				display: none;
			}
		}
	}


	&__mainTitle {
		@include visuallyHidden;
	}


	&__logo {
		display: inline-block;
		padding: $base;

		@include media('>small') {
			position: relative;
			padding: ($base * 1.5) $base3;
			@include ldi-menuHoverEffect();
		}

		// .ldi-header.ldi-js-open:not(.ldi-js-menuOverflow) &,
		body[data-ldi-current-context="menu"] .ldi-header:not(.ldi-js-menuOverflow) & {
			@include fadein;
		}

		.ldi-header:not(.ldi-js-menuOverflow).ldi-js-subMenu & {
			@include media('>small') {
				@include fadeout;
			}
		}

		.ldi-header:not(.ldi-js-menuOverflow).ldi-js-subMenu.ldi-js-subMenuAndMainMenu & {
			@include media('>small') {
				@include fadein;
			}
		}

		.ldi-header.ldi-js-open &,
		body[data-ldi-current-context="menu"] .ldi-header.ldi-js-open & {
			@include fadeout;

			@include media('>small') {
				@include fadeout;
			}
		}

		img {
			height: #{$headerMobileHeight - $base2};

			@include media('>small') {
				height: #{$headerHeight - $base3};
			}
		}
	}


	&__actions {
		position: absolute;
		top: 0;
		right: 0;
		padding: $base;

		@include media('>small') {
			.ldi-header:not(.ldi-js-menuOverflow) & {
				display: none;
			}
		}

		.ldi-header.ldi-js-open & {
			position: fixed;
			z-index: 2;
		}

		.ldi-header:not(.ldi-js-menuOverflow).ldi-js-subMenu & {
			@include media('>small') {
				@include fadeout;
			}
		}

		.ldi-header:not(.ldi-js-menuOverflow).ldi-js-subMenu.ldi-js-subMenuAndMainMenu & {
			@include media('>small') {
				@include fadeout;
			}
		}
	}

	&__closeIcon {
		.ldi-header:not(.ldi-js-open) & {
			display: none;
		}
	}

	&__openIcon {
		.ldi-header.ldi-js-open & {
			display: none;
		}
	}

	&__desktopSearch {
		.ldi-header.ldi-js-open &,
		.ldi-header.ldi-js-menuOverflow & {
			display: none;
		}
	}


	&__menu {
		position: relative;
		display: none;
		@include fadeout($easeDuration2);

		@include media('>small') {
			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include fadein;
				display: block;
				flex-grow: 1;
			}
		}

		.ldi-header.ldi-js-beforeActive & {
			display: block;
		}

		.ldi-header.ldi-js-duringActive & {
			@include fadein;
		}


		.ldi-header.ldi-js-subMenu & {
			display: none;

			&:not(.ldi-js-menuOverflow) {
				@include media('>small') {
					@include fadeout;
				}
			}
		}

		.ldi-header.ldi-js-subMenu.ldi-js-subMenuAndMainMenu & {
			@include fadein;
			display: block;

			&:not(.ldi-js-menuOverflow) {
				@include media('>small') {
					@include fadein;
				}
			}
		}
	}
}
