$buttonBorderOpacity: 0.4;
$schoolBkgOpacity: 0.8;

$easeDuration: 0.2s;
$easeDuration2: $easeDuration * 2;
$easeDuration4: $easeDuration * 4;

$videoHoverDuration: $easeDuration * 1.5;

$easeFunction: ease-out;
$easeOutCubic: 'cubic-bezier(0.22, 0.61, 0.36, 1)';
$menuTranslation: 10em;

$hoverDuration: $easeDuration;

$disabledFieldOpacity: 0.5;

$slideshowFadeDuration: 0.4s;

$videoImgOpacity: 0.4;
$videoImgOpacityHover: 0.2;
