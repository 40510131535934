
.ldi-pageActions {
	padding-top: $base2;
	padding-bottom: $base2;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;

	.block__text + & {
		padding-top: $base4;

	}

	.ldi-overlay & {
		padding-left: $containerPaddingSmall;
		padding-right: $containerPaddingSmall;

		@include media('>=small') {
			display: block;
			padding: 0;
			position: absolute;
			bottom: 0;
			right: #{0 - $iconBtnHeight - $base};
		}

		// width: $iconBtnMobileHeight;
		// height: $iconBtnMobileHeight;
		//
		// @include media('>=small') {
		// 	width: $iconBtnHeight;
		// 	height: $iconBtnHeight;
		// }
	}

	&__iconBtn {
		display: inline-block;

		.ldi-overlay & {
			@include media('>=small') {
				display: block;
				margin: $base 0 0 $base;

			}

			&:first-child {
				@include media('>=small') {
					margin-top: 0;
				}
			}
		}
	}
}
