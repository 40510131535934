
.ldi-tagSelection {
	@include clearfix;

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: -$base2;
		margin-right: -$base2;

		&:not(.ldi-js-empty) + &--tags {
			position: relative;
			// border-top: solid currentColor 1px;
			margin-top: $base3;
			padding-top: $base3;

			&::before {
				content: '';
				display: block;
				position: absolute;
				background-color: currentColor;
				height: 1px;
				top: 0;
				left: 0;
				right: $base2;
			}
		}

		&.ldi-js-empty {
			display: none;
		}
	}

	&__listElem {
		padding-right: $base2;
		padding-bottom: $base2;

		.ldi-tagSelection__list--tags & {

		}
	}
}
