
.ldi-iconButton {
	@include ease(color background-color, $hoverDuration);
	position: relative;
	background-color: $baseBgColor;
	color: $baseColor;
	display: inline-block;
	outline: none;
	border: 0;
	margin: 0 $base $base 0;
	width: $iconBtnMobileHeight;
	height: $iconBtnMobileHeight;

	@include media('>=small') {
		width: $iconBtnHeight;
		height: $iconBtnHeight;
	}

	html:not(.mobile) &:hover,
	html.mobile &:active,
	&:focus {
		background-color: $invertedBgColor;
		color: $invertedColor;
	}





	.ldi-overlay__actions & {
		margin: 0 0 0 $base;
	}

	.ldi-header__actions & {
		margin-top: 0;
		margin-bottom: 0;
		float: left;
		display: block;

		&:not(:active):not(:focus):not(:hover) {
			background-color: transparent;
		}

		&:last-child {
			margin-right: 0;
		}
	}


	span[data-type="svg"] {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: $base2;

		@include media('>=small') {
			width: $base3;

			.ldi-overlay__actions &,
			.ldi-overlay .ldi-pageActions & {
				width: $base2;
			}
		}

		svg {
			fill: currentColor;
			stroke: currentColor;
		}
	}


	&__icon {
		&--loggedIn {
			display: none;

			body.ldi-js-loggedIn & {
				display: inline-block;
			}
		}

		&--loggedOut {
			display: none;

			body:not(.ldi-js-loggedIn) & {
				display: inline-block;
			}
		}

	}
}
