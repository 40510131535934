
.ldi-partnerList {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	border-top: solid currentColor 1px;

	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		height: 1px;
		width: 100%;
		background-color: currentColor;
	}


	&__elem {
		position: relative;
		display: block;
		width: 100%;
		border-bottom: solid currentColor 1px;

		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $partnerRatio * 100%);
		}

		@include media('>xSmall') {
			width: 50%;
		}
		@include media('>small') {
			width: 33%;
		}
		@include media('>medium') {
			width: 25%;
		}

		// @include ease(background-color, $hoverDuration);

		// &:focus,
		// &:hover {
		// 	background-color: $partnerBgColor;
		// }
	}


	&__elemImg {
		@include ease(opacity filter, $hoverDuration);
		position: absolute;
		top: $base2;
		left: $base2;
		right: $base2;
		bottom: $base2;

		html.no-object-fit & {
			overflow: hidden;
		}


		&--colors {
			z-index: 2;
			.ldi-partnerList__elem--noBwImg & {
				filter: grayscale(100%);
			}

			.ldi-partnerList__elem--withBwImg & {
				opacity: 0;
			}

			html:not(.mobile) .ldi-partnerList__elem--withBwImg:hover &,
			html.mobile .ldi-partnerList__elem--withBwImg:active &,
			.ldi-partnerList__elem--withBwImg:focus & {
				opacity: 1;
			}

			html:not(.mobile) .ldi-partnerList__elem--noBwImg:hover &,
			html.mobile .ldi-partnerList__elem--noBwImg:active &,
			.ldi-partnerList__elem--noBwImg:focus & {
				filter: none;
			}

		}


		&--blackAndWhite {
			z-index: 1;
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}
}
