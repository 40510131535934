
.ldi-searchItem {
	position: relative;
	color: $baseColor;
	padding: $base2;
	border-bottom: 1px solid $baseLineColor;

	.ldi-search__resultsItem:last-child & {
		border-bottom: 0;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	&__category {
		@include ldi-fontSize(small);
		width: 100%;
		margin-bottom: $base;
	}


	&__title {
		width: 100%;
		margin-bottom: $base2;
	}


	&__thumb {
		display: none;

		figure, img {
			width: 100%;
		}

		@include media('>small') {
			display: block;
			width: $searchThumbWidth;
			margin-right: $base2;
		}
	}


	&__description {
		width: 100%;

		@include media('>small') {
			width: calc(100% - #{$searchThumbWidth} - #{$base2});
		}
	}

}
