
.ldi-blockAnimation {
	&__content {
		position: relative;
	}

	&__controller {
		display: none;
	}
}
