.ldi-slideshowNavigation {
	left: $base;
	pointer-events: none;
	position: absolute;
	right: $base;
	top: 50%;
	transform: translateY(-50%) translateY(-$base3);
	z-index: 10;


	&__arrow {
		color: inherit;
		border: 1px solid currentColor;
		height: $base4;
		padding: $base;
		pointer-events: auto;
		position: relative;
		width: $base4;

		@include media('>small') {
			height: $base5;
			width: $base5;
		}

		&--prev {
			transform: rotate(180deg);
		}

		.ldi-block--colorSchemeDefault & {
			background-color: transparentize($baseBgColor, 0.8);
		}

		.ldi-block--colorSchemeAlternative & {
			background-color: transparentize($alternativeBgColor, 0.8);
		}

		.ldi-block--colorSchemeInverted & {
			background-color: transparentize($invertedBgColor, 0.8);
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__arrows {
		display: flex;
		justify-content: space-between;
	}
}
