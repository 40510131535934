
.ldi-blocks {
	&__subBlocks {
		.ldi-columns & {
			margin-left: -$containerPaddingSmall;
			margin-right: -$containerPaddingSmall;

			@include media('>small') {
				margin-left: -$containerPadding;
				margin-right: -$containerPadding;
			}
		}
	}
}
