
.ldi-body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	visibility: visible;
	position: relative;
	z-index: 2;


	&.ldi-js-inactive,
	&.ldi-js-covered,
	body.ldi-js-searchMode & {
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 1;
	}

	&::after {
		@include position(absolute, 0, 0, 0, 0);
		@include fadeout;
		content: '';
	}

	&.ldi-js-covered--menu::after {
		background: $baseBgColor;
	}


	&.ldi-js-covered::after,
	body.ldi-js-searchMode &::after {
		@include fadein;
		z-index: 1000;
		background: rgba($baseColor, 0.9);
	}


	&.ldi-js-loading::after {
		@include fadein;
		z-index: 2;
		background: $baseBgColor;
	}
}
