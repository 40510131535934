.ldi-block {
	&__text {
		margin-left: auto;
		margin-right: auto;
		// margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}

		.ldi-block__title + & {
			margin-top: $base3;

			@include media('>small') {
				margin-top: $base5;
			}
		}


		.ldi-block--linkedTile &,
		.ldi-block--teaser &,
		.ldi-block--search & {
			@include ldi-fontSize(h1);
		}

		&--important {
			@include ldi-fontSize(important);
		}

		& + & {
			margin-top: $base2;
		}


		.ldi-block--video & {
			margin-top: $base2;

			@include media('>small') {
				margin-top: $base4;
			}
		}

		.ldi-block--video .ldi-block__title + & {
			margin-top: $base;

			@include media('>small') {
				margin-top: $base2;
			}
		}

		blockquote {
			margin-left: 0;
		}
	}
}
