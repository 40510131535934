$htmlFontSize: 62.5%;
$htmlFontSizeSmallFactor: 1 !default;

$fontSizeBase: 18;

@function fontSizeBase($value: $fontSizeBase) {
	@return $value / 10 * 1rem;
}

@function fontSizeSmall($value) {
	@return $value / $htmlFontSizeSmallFactor / 10 * 1rem;
}

$fontSizes: (
	base: (
		size: fontSizeSmall(16),
		line-height: 1.55,
		margin-bottom: $base2,
		variants: (
			'>small': (
				size: fontSizeBase(18),
			),
		),
	),
	small: (
		size: fontSizeSmall(14),
		line-height: 1.55,
		margin-bottom: $base2,
		variants: (
			'>small': (
				size: fontSizeBase(16),
			),
		),
	),
	h1: (
		size: fontSizeSmall(24),
		line-height: 1.2,
		margin-bottom: $base2,
		variants: (
			'>small': (
				size: fontSizeBase(30),
			),
		),
	),
	h2: (
		size: fontSizeSmall(20),
		line-height: 1.12,
		margin-bottom: $base,
		variants: (
			'>small': (
				size: fontSizeBase(25),
			),
		),
	),
	important: (
		size: fontSizeSmall(18),
		line-height: 1.55,
		margin-bottom: $base2,
		variants: (
			'>small': (
				size: fontSizeBase(22),
			),
		),
	),
	caption: (
		size: fontSizeSmall(13),
		line-height: 1.3,
		variants: (
			'>small': (
				size: fontSizeBase(14),
			),
		),
	),
	videoCaption: (
		size: fontSizeSmall(13),
		line-height: 1.3,
		variants: (
			'>small': (
				size: fontSizeBase(15),
			),
		),
	),
	smallerCaption: (
		size: fontSizeSmall(13),
		line-height: 1.3,
		variants: (
			'>small': (
				size: fontSizeBase(13),
			),
		),
	),
	newsletterButton: (
		size: fontSizeSmall(20),
		line-height: 1,
		variants: (
			'>small': (
				size: fontSizeBase(20),
			),
		),
	),
);
