
.ldi-videoWall {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -$base2;

	@include media('>small') {
		margin-bottom: -$base3;
	}


	* + & {
		margin-top: $base3;

		@include media('>small') {
			margin-top: $base5;
		}
	}


	&__elem {
		width: 100%;
		margin-bottom: $base2;

		@include media('>small') {
			margin-bottom: $base3;
			margin-left: 0;
			margin-right: 0;
		}
		// @include media('>small') {
		// 	margin-left: $base2;
		// 	margin-right: $base2;
		// }

		@include ldi-tilesWallColumnsDefault;
	}

	&__link {
		color: $invertedColor;
		background-color: $invertedBgColor;
		position: relative;
		display: block;

		html.no-object-fit & {
			overflow: hidden;
		}

		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $videoPlayerRatio * 100%);
		}
	}

	&__bgImg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		img {
			@include ease(opacity, $easeDuration);
			@include objectFit(cover);
			display: block;
			width: 100%;
			height: 100%;
			opacity: $videoImgOpacity;

			body.ldi-js-highContrast & {
				opacity: 0.1;
			}

			.ldi-videoWall__link:active &,
			html:not(.mobile) .ldi-videoWall__link:hover &,
			html:not(.mobile) .ldi-videoWall__link:focus & {
				opacity: $videoImgOpacityHover;
			}

			body.ldi-js-highContrast .ldi-videoWall__link:active &,
			html:not(.mobile) body.ldi-js-highContrast .ldi-videoWall__link:hover &,
			html:not(.mobile) body.ldi-js-highContrast .ldi-videoWall__link:focus & {
				opacity: 0.05;
			}
		}
	}


	&__playerBtnIcon {
		display: block;
		left: $base;
		line-height: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: $base3;

		@include media('>small') {
			left: $base3;
		}

		span[data-type="svg"] {
			width: 100%;
			height: auto;

			// @include media('>xSmall') {
			// 	height: $base6;
			// }
		}
	}


	&__label,
	&__title {
		@include ldi-fontSize(videoCaption);
		color: $invertedColor;
		left: $base5;
		position: absolute;
		right: $base;
		top: 50%;
		transform: translateY(-50%);

		@include media('>small') {
			left: $base8;
			right: $base2;
		}
	}
}
