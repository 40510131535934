.ldi-menu {
	&--main {
		.ldi-header:not(.ldi-js-menuOverflow) & {
			@include media('>small') {
				//overflow: hidden;
				//margin-bottom: -$headerLineSize;
			}
		}
	}

	&--footer {
		border-top: solid $footerBgColor 1px;
		border-bottom: solid $footerBgColor 1px;
		background-color: $footerBgColor;
		color: $footerColor;
	}

	&--subFooter {
		border-top: solid $subFooterBgColor 1px;
		border-bottom: solid $subFooterBgColor 1px;
		background-color: $subFooterBgColor;
		color: $subFooterColor;
		font-weight: normal;
	}


	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;

		html.ms & {
			justify-content: space-around;
		}

		&--social {
			flex-wrap: nowrap;
			justify-content: flex-start;
		}

		.ldi-menu--main & {
			display: block;

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					height: 100%;
					// word-break: keep-all;
					white-space: nowrap;
					text-align: right;

					display: flex;
					flex-direction: row;
					justify-content: flex-end;
				}
			}
		}


		.ldi-menu--subMenu & {
			// flex-grow: 0;
			display: block;
			//margin-bottom: -$headerLineSize;

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					height: 100%;
					white-space: nowrap;
					text-align: right;
					// justify-content: flex-end;
					// display: flex;
				}
			}
		}
	}


	&__listElem {
		@mixin hideOnLargeScreen() {
			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					display: none;
				}
			}
		}

		body.ldi-js-highContrast &[data-ldi-link="#enableHighContrast"],
		body:not(.ldi-js-highContrast) &[data-ldi-link="#disableHighContrast"] {
			display: none;
		}

		.ldi-header:not(.ldi-js-menuOverflow) & {
			@include media('>small') {
				@include ldi-fontSize(base);
				position: relative;
				display: inline-block;
				height: 100%;
				flex-grow: 0;
				flex-shrink: 0;
				order: 1;
			}

			&--account {
				@include media('>small') {
					order: 100;
				}
			}

			&--search {
				@include media('>small') {
					order: 200;
				}
			}
		}

		.ldi-subMenu & {
			&:nth-child(1) {
				@include hideOnLargeScreen();
				font-weight: bold;
			}
		}

		.ldi-subMenu--competition & {
			// showed only on small screen
			&:nth-child(2) {
				@include hideOnLargeScreen();
			}
		}

		.ldi-menu__list--social &:not(:first-child) {
			margin-left: $base;
		}
	}


	&__link {
		display: block;
		padding: $base #{$base * 2/3} $base #{$base * 2/3};

		.ldi-menu--subMenu &,
		.ldi-menu--main & {
			@include ease(color background-color border-top border-bottom, $hoverDuration);
			position: relative;
			border-bottom: solid $headerLineSize $headerLineColor;
			border-top: solid $headerLineSize transparent;
			// margin-top: -$headerLineSize;
			// margin-bottom: -$headerLineSize;
			margin-top: 0;
			padding: $base2 0;

			&:focus,
			html:not(.mobile) &:hover,
			html.mobile &:active {
				z-index: 1;
				background-color: $baseBgColor;
				border-bottom: solid $headerLineSize currentColor;
				border-top: solid $headerLineSize transparent;
			}

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					height: $headerHeight;
					height: 100%;
					border: 0;
					// border-left: solid $headerLineSize $headerLineColor;
					padding: $base1 $base3;
					margin-top: 0;
					margin-bottom: 0;
					word-break: keep-all;
					white-space: nowrap;
					@include ldi-menuHoverEffect(true);

					&:focus,
					html:not(.mobile) &:hover,
					html.mobile &:active {
						background-color: transparent;
						border: 0;
					}
				}
			}
		}


		.ldi-menu--subMenu & {
			.ldi-header.ldi-js-subMenuAndMainMenu:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					//height: $subMenuSmallerHeight;
				}
			}
			// border-color: transparent;

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					// border-color: transparent;
					// margin-top: $headerLineSize;
					// margin-bottom: -$headerLineSize;

					&::before {
						// top: $headerLineSize;
					}

					&::after {
						// bottom: $headerLineSize;
					}
				}
			}
		}


		.ldi-menu--main .ldi-menu__listElem:first-child & {
			border-top: solid $headerLineSize $headerLineColor;

			&:focus,
			&:hover {
				border-top-color: currentColor;
			}

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					border-top: 0;
				}
			}
		}
	}

	&__linkLabel {
		@include ease(border-bottom-color, $hoverDuration);

		&:focus,
		&:hover {
			border-bottom: 1px solid currentColor;
		}

		.ldi-menu__list--social &,
		.ldi-menu--subMenu &,
		.ldi-menu--main & {
			&:focus,
			&:hover {
				border-bottom: 0;
			}

			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					display: block;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}


	&__linkIcon {
		.ldi-menu--main & {
			.ldi-header:not(.ldi-js-menuOverflow) & {
				@include media('>small') {
					display: block;
					position: relative;
					height: $base2;
					top: 50%;
					transform: translateY(-50%);
				}

				&--loggedIn {
					display: none;

					body.ldi-js-loggedIn & {
						@include media('>small') {
							display: block;
						}
					}
				}

				&--loggedOut {
					display: none;

					body:not(.ldi-js-loggedIn) & {
						@include media('>small') {
							display: block;
						}
					}
				}
			}
		}

		span[data-type="svg"] {
			height: 100%;
		}
	}
}
