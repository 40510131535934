.ldi-dialogContext {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 500;
	align-items: center;
	justify-content: center;
	display: none;
	visibility: hidden;
	opacity: 0;
	@include ease(visibility opacity, $easeDuration2);

	&.ldi-js-beforeActive {
		display: block;
	}


	&.ldi-js-duringActive {
		visibility: visible;
		opacity: 1;
	}

	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	left: 0;
	// 	top: 0;
	// 	z-index: 1;
	// 	background-color: $baseColor;
	// 	opacity: 0.5;
	// }

}
