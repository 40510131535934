
.ldi-tagsList {


	&__items {
		margin-bottom: -$base2;


		& + & {
			margin-top: $base2;
			border-top: 1px solid $baseLineColor;
			padding-top: $base2;

			.ldi-search & {
				border-top: 1px solid $baseBgColor;
			}
		}
	}


	&__item {
		display: inline-block;
		margin-right: $base2;
		margin-bottom: $base2;

		&:last-child {
			margin-right: 0;
		}


		&.ldi-js-hidden {
			display: none;
		}
	}

}
