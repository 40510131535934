
.ldi-blockTilesWall {
	&__tile {
		@include ldi-animateOnIntersection();
		position: relative;
		// display: flex;
		justify-content: center;
		// align-items: center;
		// margin-bottom: 1px;

		@include media('>small') {
			// margin-left: $base;
			// margin-right: $base;
		}

		@include ldi-tilesWallColumnsDefault();
	}
}
