
html, body {
	@include sansSerif();
}


// keep the scrollbar visible during search to avoid ugly "jumps" on Windows, where the scrollbar take space in the viewport
body.ldi-js-searchMode {
	overflow-y: scroll;
}


h1 {
	@include ldi-fontSize(h1);
	// @include margin-bottom(h1);
}

h2 {
	@include ldi-fontSize(h1);
	// @include margin-bottom(h1);
}

h3 {
	@include ldi-fontSize(h2);
	// @include margin-bottom(h2);
}

h4, h5, h6 {
	@include ldi-fontSize(base);
	// @include margin-bottom(base);
}


p {
	@include margin-bottom(base);

	&:last-child {
		margin-bottom: 0;
	}

	a {
		font-weight: bold;
		text-decoration: none;
		// border-bottom: 1px solid currentColor;
		// @include ease(border-bottom-color, $hoverDuration);

		&:focus,
		&:hover {
			// color: $linkColor;
			border-bottom-color: transparent;
		}
	}

}

a {
	text-decoration: none;

	&, &:focus, &:hover, &:active, &:visited {
		outline: 0 !important;
	}
}


figcaption {
	@include ldi-fontSize(caption);
	text-align: left;
	margin-top: $base;
}
