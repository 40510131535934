.ldi-blockThumbnailCollection {
	text-align: center;

	.ldi-block__title {
		margin-bottom: $base;

		@include media('>small') {
			margin-bottom: $base4;
		}
	}

	&__collection {
		max-width: $mainMaxWidth;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	&__thumbnail {
		position: relative;
		display: block;
		text-align: left;

		padding-left: $base;
		padding-right: $base;
		padding-bottom: $base2;


		@include media('>xSmall') {
			width: 50%;
			display: inline-block;
			vertical-align: top;
		}

		@include media('>small') {
			width: $base * 24;
			padding-bottom: $base4;
		}

		// &:first-child {
		// 	@include clearfix();
		// 	position: relative;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// }

		.ldi-blockThumbnailCollection__collection--single & {
			@include media('>small') {
				width: 60%;
				padding-left: $base * (16 + 2);
				min-height: $base * (16 + 2);
			}
		}
	}

	&__thumbnailImg {
		width: $base * 16;
		height: $base * 16;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto;

		@include media('>xSmall') {
			margin-left: 0;
		}


		.ldi-blockThumbnailCollection__collection--single & {
			@include media('>small') {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}

		& + * {
			margin-top: $base2;
		}
	}
}
