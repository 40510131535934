
.ldi-account {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	width: auto;
	background-color: $baseBgColor;

	@include media('>small') {
		margin-right: $iconBtnHeight + $base;
	}


	&__block {
		@include ldi-container();
		max-width: none;
		@include ease(visibility opacity, $easeDuration);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;

		@include media('>small') {
			padding-top: $containerPadding;
			padding-bottom: $containerPadding;
		}

		&.ldi-js-current {
			position: relative;
			visibility: inherit;
			opacity: 1;
		}
	}


	&__message {
		margin-bottom: $base5;

		p {
			margin-top: $base2;
		}
	}
}
