.ldi-projects {
	padding-bottom: $base7;

	.ldi-block__text + & {
		margin-top: $base5;
	}

	&__item {
		padding: $base2 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid currentColor;

		&:first-child {
			border-top: 1px solid currentColor;
		}
	}


	&__itemTitle {
		width: calc(50% - #{$base4});
	}

	&__itemActions {
		width: calc(50% - #{$base4});
		text-align: right;
	}

	&__itemProgress {
		width: $base8;
		text-align: center;
	}


	&__itemName {
		display: block;
	}

	&__itemContest {
		@include ldi-fontSize(small);
		display: block;
		opacity: 0.7;
	}


	&__contestDescriptions {
		position: relative;
		margin-top: $base3;
	}


	&__contestDescription {
		@include ease(visibility opacity, $easeDuration);
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		max-width: $textMaxWidth;

		&.ldi-js-current {
			position:relative;
			visibility: inherit;
			opacity: 1;
		}
	}


	&__itemAction {
		margin-left: $base2;

		&:first-child {
			margin-left: 0;
		}
	}


	&__submittedIcon {
		position: relative;
		display: inline-block;
		width: $base3;
		height: $base3;

		span[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__text {
		* + & {
			margin-top: $base5;
		}
	}

}
