
@mixin ldi-animateOnIntersection() {
	opacity: 0;
	transform: translateY(10vh);

	body.ldi-js-highContrast &,
	&.ldi-js-insideViewport {
		@include ease(transform opacity, $easeDuration2 * 1.5);
		opacity: 1;
		transform: none;
	}
}
