@font-face {
	font-family: "Futura LT W01";
	// Light
	src: url("../fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix");
	src:
		url("../fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"),
		url("../fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"),
		url("../fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"),
		url("../fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"),
		url("../fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");

	font-weight: normal;
}

@font-face {
	font-family: "Futura LT W01";
	// Medium
	src: url("../fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix");
	src:
		url("../fonts/0a41510f-c527-4001-89c5-d66d2c9ed34b.eot?#iefix") format("eot"),
		url("../fonts/e17c7943-8db2-4470-9e2a-74a8d01f6776.woff2") format("woff2"),
		url("../fonts/6c72b0cb-89a1-4da9-8574-9244967bd9d6.woff") format("woff"),
		url("../fonts/dd79c75a-b0c9-4d70-a5a7-bdde1d5449fd.ttf") format("truetype"),
		url("../fonts/c35c8eec-24d3-4ed7-9159-df80a008bf54.svg#c35c8eec-24d3-4ed7-9159-df80a008bf54") format("svg");
	font-weight: bold;
}


@mixin sansSerif() {
	font-family: "Futura LT W01", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
