@mixin button($space: 1em) {
	text-decoration: none;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	position: relative;


	&::before {
		content: '\00a0';
		display: block;
		width: 1px;
		margin-left: -1px;
		overflow: hidden;
		visibility: hidden;
		position: relative;
		z-index: 1;
	}


	&__label,
	&__icon,
	&__icon svg {
		display: inline-block;
		flex-grow: 0;
		flex-shrink: 0;
	}


	&__label {
		flex-grow: 1;
	}


	&__icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		align-content: center;

		figure,
		span[data-type="svg"] {
			width: inherit;
			height: inherit;
			display: inline-block;
			flex-grow: 0;
			flex-shrink: 0;
		}
	}


	&__label + &__icon,
	&__icon + &__label {
		margin-left: $space;
	}
}
