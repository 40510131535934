
.ldi-subMenu {
	@include ease(opacity visibility min-height, $easeDuration);
	position: absolute;
	width: 100%;
	height: auto;
	top: 100%;
	right: 0;
	left: 0;
	background-color: $headerBgColor;
	z-index: 1;
	padding-left: $containerPaddingSmall;
	padding-right: $containerPaddingSmall;
	padding-bottom: $base4;
	opacity: 0;
	visibility: hidden;

	.ldi-header:not(.ldi-js-menuOverflow) & {
		@include media('>small') {
			visibility: inherit;
			opacity: 1;
			min-height: calc(#{$headerHeight} + #{$headerLineSize});
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: stretch;
			// align-content: center;

			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
			border-bottom: solid $headerLineSize $headerLineColor;
			margin-top: $headerLineSize;
		}
	}

	html:not(.ie) .ldi-header.ldi-js-subMenuAndMainMenu:not(.ldi-js-menuOverflow) & {
		@include media('>small') {
			min-height: calc(#{$subMenuSmallerHeight} + #{$headerLineSize});
		}
	}

	.ldi-header.ldi-js-open & {
		visibility: inherit;
		opacity: 1;
	}


	&::before {
		content: '';
		position: absolute;
		display: block;
		background-color: $headerLineColor;
		height: $headerLineSize;
		top: 0;
		left: $containerPaddingSmall;
		right: $containerPaddingSmall;

		.ldi-header:not(.ldi-js-menuOverflow) & {
			@include media('>small') {
				display: none;
			}
		}
	}


	&__subHome {
		display: none;

		.ldi-header:not(.ldi-js-menuOverflow) & {
			@include media('>small') {
				position: relative;
				padding: $base1 $base3 $base1 $base3;
				margin-bottom: -$headerLineSize;
				white-space: nowrap;
				@include ldi-menuHoverEffect();
				display: flex;
				flex-direction: column;
				justify-content: center;

				html.ie & {
					height: $headerHeight;
				}
			}
		}
	}


	&__subHomeLogo {
		height: $base6;
		width: auto;

	}


	&__close {
		display: none;
		padding: $base $base2 $base $base2;

		.ldi-header:not(.ldi-js-menuOverflow) & {
			@include media('>small') {
				@include clearfix;
				position: absolute;
				top: -50%;
				left: 0;
				height: 50%;
				width: 100%;
				display: block;
				//overflow: hidden;
			}
		}

		.ldi-header:not(.ldi-js-subMenu) & {
			@include media('>small') {
				@include fadeout;
			}
		}

		.ldi-header.ldi-js-subMenuAndMainMenu & {
			@include fadeout;
		}
	}

	&__closeDot {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		width: $logoDotSize * 6;
		height: $logoDotSize * 6;
		margin-left: -$logoDotSize * 3;
		margin-top: -$logoDotSize * 3;
		//transform-origin: 0 0;
		transform: scale(0.2);
		border-radius: 50%;

		@for $i from 0 through 8 {
			$pos: -$logoDotSize * (6 - 1.5 * $i);

			&:nth-child(#{$i + 1}) {
				transform: translateX($pos) scale(0.2);
			}
		}

		&:nth-child(-n + 3) {
			background-color: $logoColor1;
		}

		&:nth-child(n + 4):nth-child(-n + 6) {
			background-color: $logoColor2;
		}

		&:nth-child(n + 6) {
			background-color: $logoColor3;
		}
	}
}
