.ldi-field {
	margin-top: $base3;
	width: 100%;

	&[data-ldi-field-hidden="true"] {
		display: none;
	}

	@include media('>small') {
		margin-left: $base2;
	}

	&--imagefile {
		&.ldi-js-progress::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 10;
		}
	}


	&--text,
	&--password,
	&--select,
	&--file {
		.ldi-field--textarea + &,
		.ldi-field--tags + & {
			margin-top: $base5;
		}

		@include media('>small') {
			flex-grow: 0;
			flex-shrink: 0;
			width: calc(50% - #{$base2});
		}

		.ldi-project .ldi-field--group & {
			@include media('>small') {
				width: 100%;
			}
		}
	}


	&--textarea + &:not(&--textarea):not(&--tags),
	&--textarea + &:not(&--textarea):not(&--tags) + &:not(&--textarea):not(&--textarea),
	&--tags + &:not(&--textarea):not(&--tags),
	&--tags + &:not(&--textarea):not(&--tags) + &:not(&--textarea):not(&--textarea) {
		margin-top: $base5;
	}


	&--textarea,
	&--tags {
		margin-top: $base5;

		&:first-child {
			margin-top: $base3;
		}
	}


	&__container {
		.ldi-field .ldi-field & {
			padding-left: 0;
			padding-right: 0;
		}
	}


	&__content {
		.ldi-field--disabled & {
			opacity: $disabledFieldOpacity;
		}

		.ldi-field--group.ldi-field--hasActions > .ldi-field__container > &--hasActions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.ldi-field--group.ldi-field--sortableItem > .ldi-field__container > & {
			position: relative;
			padding-left: $base3;
		}
	}


	&__fields {
		position: relative;
		z-index: 1;

		@include media('>small') {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin-left: -$base2;
			margin-top: -$base3;
		}

		.ldi-project .ldi-field--group & {
			@include media('>small') {
				flex-direction: row-reverse;
			}
		}
	}

	&__actions {
		.ldi-field--group.ldi-field--hasActions > .ldi-field__container > .ldi-field__content--hasActions > & {
			flex-grow: 0;
			flex-shrink: 0;
		}

		&--array {
			margin-top: $base2;
		}
	}


	&__label {
		@include ldi-fontSize(base);
		display: block;
		position: relative;
		margin-bottom: $base * 1.5;
		font-weight: 400;

		.ldi-field--radio.ldi-field--required &--inputLabel {
			&::after {
				display: none;
			}
		}

		.ldi-field--checkbox &,
		.ldi-field--radio &--inputLabel {
			position: relative;
			padding-left: $base4;
		}

		.ldi-field--checkbox & {
			margin-bottom: 0;
		}

		.ldi-field--radio &--inputLabel {
			display: inline-block;
			margin-right: $base2;

			&:last-child {
				margin-right: 0;
			}
		}


		.ldi-project &:not(.ldi-field__label--inputLabel) {
			padding-right: $base3;
		}
	}


	&__labelValue {
		.ldi-field--required & {
			> p:last-child::after {
				content: '*';
			}
		}
	}

	&__inputIcon {
		@include ease(color opacity);
		position: absolute;
		left: 0;
		top: 0;
		// transform: translateY(-50%);
		width: $base3;
		height: $base3;
		color: inherit;

		span[data-type="svg"] {
			color: inherit;
			width: 100%;
			height: auto;
		}

		.ldi-field__input:focus + .ldi-field__label & {
			color: $baseColor;
		}

		.ldi-field__input:checked + .ldi-field__label &--unchecked,
		.ldi-field__input:not(:checked) + .ldi-field__label &--checked {
			opacity: 0;
		}
	}


	&__selectIcon {
		position: absolute;
		right: 0;
		top: 0;
		width: $base4;
		height: 100%;
		color: $baseColor;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}


	&__inputWrapper {
		position: relative;

		.ldi-field--select & {
			background-color: $baseBgColor;
		}
	}


	&__input {
		&--checkbox,
		&--radio {
			@include visuallyHidden();
		}

		&--text,
		&--password,
		&--textarea,
		&--tags,
		&--select,
		&--date,
		&--time {
			@include ease(border-left-color);
			@include ldi-fontSize(base);
			@include sansSerif();
			border: 1px solid $baseColor;
			width: 100%;
			padding: ($base * 0.7) $base $base $base2;
			color: $baseColor;
			background-color: $baseBgColor;

			&:focus {
				outline: none;
			}

			.ldi-field.ldi-js-invalid & {
				border-color: $errorColor;
			}
		}



		&--textarea,
		&--tags {
			min-height: $minTextareaHeight;
			max-height: $maxTextareaHeight;
			padding: $base $base ($base * 1.5) $base2;
		}


		&--select {
			position: relative;
			z-index: 2;
			-webkit-appearance: none;
			-moz-appearance: none;
			background-color: transparent;
			padding-right: $base4;

			option {
				@include ldi-fontSize(base);
				@include sansSerif();
				padding: ($base / 2) $base;
			}
		}


		&--file {
			font-size: 200px;
			position: absolute;
			top: 0;
			right: 0;
			opacity: 0;
			z-index: 2;
			cursor: pointer;
		}
	}


	&__datetimeWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__datetimeDate,
	&__datetimeTime {
		flex-shrink: 0;
	}

	&__datetimeDate {
		flex-grow: 1;
		margin-right: $base2;
	}

	&__datetimeTime {
		flex-grow: 0;
	}


	&__extra {
		@include ldi-fontSize(small);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}


	&__errors {
		flex-shrink: 1;
		flex-grow: 1;
	}


	&__charsCounter {
		padding-top: $base / 2;
		padding-left: $base2;
		flex-shrink: 0;
		flex-grow: 0;

		&.ldi-js-negative {
			color: $errorColor;
		}
	}



	&__error {
		padding-top: $base / 2;
		padding-left: $base2;
		color: $errorColor;
	}



	&__sortableHandle {
		position: absolute;
		left: -$base / 2;
		top: $base;
		width: $base2;
		cursor: ns-resize;
	}


	&__labelRequired,
	&__labelOptional {
		display: none;
	}


	&__fileButtonContainer {
		position: relative;
	}


	&__fileUploadProgress {
		background-color: currentColor;
		position: relative;
		height: 2px;
		visibility: hidden;
		width: 0%;

		.ldi-field.ldi-js-progress & {
			visibility: inherit;
		}
	}


	&__imagefileFieldWrapper {
		width: 100%;

		@include media('>small') {
			flex-grow: 0;
			flex-shrink: 0;
			width: calc(40% + #{$base2});
			padding-left: $base2;
			margin-right: $base2;
		}
	}

	&__imageFieldsWrapper {
		width: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__image {
		position: relative;
	}

	&__imageUpload {
		margin-top: $base2;

		.ldi-field.ldi-js-progress & {
			opacity: 0.3;
		}
	}


	&__imageInfo {
		@include ldi-fontSize(small);
		margin-top: $base;

		.ldi-field.ldi-js-empty:not(.ldi-js-progress) & {
			display: none;
		}
	}

	&__fileInfo {
		@include ldi-fontSize(small);
		margin-top: $base;

		.ldi-field.ldi-js-empty:not(.ldi-js-progress) & {
			display: none;
		}
	}


	&__limitsInfo {
		@include ldi-fontSize(small);
		margin-top: $base;
	}


	&__imageProgress {
		@include ldi-fontSize(important);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 3;
		font-weight: bold;
		display: none;


		.ldi-field.ldi-js-progress & {
			display: block;
		}
	}


	&__removeFile {
		margin-top: $base2;

		@include media('>small') {
			bottom: 0;
			margin-top: 0;
			min-width: 0;
			position: absolute;
			right: 0;
			width: auto;
			z-index: 3;
		}

		.ldi-field.ldi-js-empty &,
		.ldi-field.ldi-js-progress & {
			display: none;
		}
	}


	&__removeImage {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
		width: auto;
		min-width: 0;

		.ldi-field.ldi-js-empty &,
		.ldi-field.ldi-js-progress & {
			display: none;
		}
	}

	&__imgPlaceholder {
		position: relative;
		display: none;
		width: 100%;
		border: 1px solid $baseColor;

		.ldi-field.ldi-js-empty:not(.ldi-js-progress) & {
			display: block;
		}

		&::before {
			content: ' ';
			display: block;
			padding-bottom: (1 / $videoPlayerRatio * 100%);
		}
	}

	&__imgPlaceholderLabel {
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		padding: $base2;
		text-align: center;
	}


	&__img {
		position: relative;
		width: 100%;
		height: auto;
		z-index: 1;

		.ldi-field.ldi-js-empty & {
			display: none;
		}

		.ldi-field.ldi-js-progress & {
			display: block;
			opacity: 0.5;
		}

		&--progress {
			@include ease(clip);
			position: absolute;
			left: 0;
			top: 0;
			clip: rect(0, 0, auto, auto);
			display: none;
			z-index: 2;

			.ldi-field.ldi-js-progress & {
				display: block;
				opacity: 1;
			}
		}
	}


	&__checkIcon {
		position: absolute;
		bottom: 0;
		right: 0;
		width: $base2;
		height: $base2;
		color: $checkIconColor;
		visibility: hidden;
		clip: rect(auto, 0%, auto, auto);
		display: none;

		.ldi-project & {
			display: block;
		}


		span[data-type="svg"] {
			width: 100%;
		}
	}
}
