
.ldi-search {
	position: relative;
	color: $baseColor;

	.ldi-block--colorSchemeInverted &,
	&.ldi-js-overlay {
		color: $invertedColor;
	}

	.ldi-block--colorSchemeAlternative & {
		color: $alternativeColor;
	}

	.ldi-block--colorSchemeDmp2019 & {
		color: $dmp2019Color;
	}


	&.ldi-js-hidden {
		visibility: hidden;
		touch-action: none;
	}


	&.ldi-js-overlay {
		position: absolute;
		max-width: none;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 100;

		&.ldi-js-searchPreparing {
			@include ease(transform, $easeDuration2 * 2, $easeFunction, $easeDuration);
			position: relative;
		}
	}

	&__toolbar {
		@include ease(background-color, $easeDuration2);
		position: relative;
		padding-top: $base3;
		padding-bottom: $base3;
		z-index: 10;

		.ldi-search.ldi-js-overlay & {
			@include ldi-container();
			max-width: none;
		}

		.ldi-search.ldi-js-searchReady & {
			left: 0;
			top: 0;
			position: sticky;
			background-color: $overlayFullBgColor;
		}

	}


	&__inputContent {
		position: relative;

		.ldi-search.ldi-js-overlay & {
			width: 100%;
			margin: auto;
		}
	}

	&__inputBox {
		max-width: $blockContentMaxWidth;
		margin: auto;
		position: relative;
		left: 0;
		top: 0;
		overflow: hidden;
		min-height: $base6;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;


		&::after {
			@include ease(transform, $easeDuration2);
			content: '';
			position: absolute;
			width: 100%;
			height: 0;
			left: 0;
			bottom: 0;
			border-bottom: 1px solid $baseLineColor;

			.ldi-block--colorSchemeInverted &,
			.ldi-search.ldi-js-overlay & {
				border-bottom-color: $invertedLineColor;
			}

			.ldi-block--colorSchemeAlternative & {
				border-bottom-color: $alternativeLineColor;
			}

			.ldi-search.ldi-js-searchReady & {
				transform: translateX(-$base8);
			}
		}
	}

	&__label {
		@include ldi-fontSize(h2);
		display: block;
		position: relative;
	}


	&__input {
		@include ldi-fontSize(base);
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		line-height: 1.3;
		display: block;
		border: 0;
		width: 100%;
		padding-right: $base8;
		padding-left: $base3;
		background-color: transparent;
		color: currentColor;
		padding-bottom: $base;
		z-index: 2;

		.ldi-search__label + & {
			margin-top: $base2;
		}

		@include media('>small') {
			@include ldi-fontSize(h1);
			line-height: 1.3;
			padding-left: $base4;
		}


		&::placeholder {
			color: inherit;
			// placeholder opacity is lower than 1 in FF by default
			opacity: 0.7;
		}
	}


	&__icon {
		position: absolute;
		left: 0;
		bottom: 0;
		width: $base2;
		height: $base3;
		padding-bottom: $base;
		z-index: 1;

		@include media('>small') {
			width: $base2;
			height: $base4;
			padding-bottom: $base2;
		}


		span[data-type="svg"] {
			position: relative;
			width: $base2;
			height: $base2;
		}
	}


	&__close {
		position: absolute;
		right: 0;
		bottom: 0;
		width: $base6;
		height: $base6;
		border: 1px solid $baseLineColor;
		visibility: hidden;
		opacity: 0;
		z-index: 3;

		.ldi-block--colorSchemeInverted &,
		.ldi-search.ldi-js-overlay & {
			border-color: $invertedLineColor;
		}

		.ldi-block--colorSchemeAlternative & {
			border-color: $alternativeLineColor;
		}

		.ldi-search.ldi-js-searchReady & {
			@include ease(visibility opacity, $easeDuration, $easeFunction, $easeDuration * 3);
			visibility: inherit;
			opacity: 1;
		}
	}

	&__closeButton {
		@include ease(color background-color, $easeDuration);
		position: absolute;
		left: -1px;
		right: -1px;
		top: -1px;
		bottom: -1px;
		padding: $base2;

		.ldi-block--colorSchemeInverted &,
		.ldi-search.ldi-js-overlay & {
			&:focus,
			html:not(.mobile) &:hover,
			html.mobile &:active {
				background-color: $baseBgColor;
				color: $baseColor;
			}
		}
	}


	&__closeIcon {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 100%;

		span[data-type="svg"] {
			width: 100%;
			height: 100%;
			// animation-name: spin;
			// animation-duration: 3s;
			// animation-direction: normal;
			// animation-fill-mode: forwards;
			// animation-iteration-count: 1;
			// animation-timing-function: linear;
			// animation-play-state: running;

			// .ldi-search.ldi-js-busy & {
			// 	animation-iteration-count: infinite;
			// }
		}
	}


	&__results {
		@include ldi-container();
		padding-top: $base2;
		display: none;
		max-width: none;
		margin-bottom: $base8;

		.ldi-search.ldi-js-searchReady & {
			display: block;
		}
	}

	&__resultsContent {
		max-width: $blockContentMaxWidth; //$mainxMaxWidth;
		margin: auto;
	}


	&__resultsItems {
		color: $baseColor;
		background-color: $baseBgColor;

		.ldi-tagsList + & {
			margin-top: $base2;
		}
	}
}
